input {
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
}

.head {
  box-sizing: border-box;
  border-bottom: unset;
  width: 100%;
}

.head-left {
  width: auto;
  border-right: unset;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  box-sizing: border-box;
}

.showfilter {
  display: flex;
  align-items: center;
  font-size: 15px;
  text-transform: uppercase;
}

.showfilter-btn {
  min-width: 27px;
  max-width: 27px;
  min-height: 27px;
  max-height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 13px;
  transition-duration: 0.2s;
}

.showfilter-btn-hover {
  display: none;
}

.showfilter-btn {
  &.hover {
    background-color: rgb(124, 92, 173);
  }

  &:hover {
    background-color: rgb(124, 92, 173);

    .showfilter-btn-hover {
      display: block;
    }
  }

  &.hover .showfilter-btn-hover {
    display: block;
  }

  &:hover .showfilter-btn-default, &.hover .showfilter-btn-default {
    display: none;
  }
}

.filters {
  a {
    text-decoration: none;

    &.filters-clear {
      font-size: 14px;

      &.disabled {
        pointer-events: none;
        color: darkgrey;
      }
    }
  }

  //TODO
  //font-family: Myriad;
  padding: 15px 20px;
  width: 250px;
  box-sizing: border-box;

  .ui-slider {
    position: relative;

    .ui-slider-handle {
      position: absolute;
      z-index: 2;
      width: 9px;

      /* Задаем нужную ширину */
      height: 9px;

      /* и высоту */
      background: #7c5cad;

      /* картинка изображающая ползунок. Или можно залить цветом, задать бордюр и скругления */
      cursor: pointer;
    }

    .ui-slider-range {
      position: absolute;
      z-index: 1;
      font-size: .7em;
      display: block;
      border: 0;
      overflow: hidden;
    }
  }

  /* Ползунок */

  /* горизонтальный слайдер (сама полоса по которой бегает ползунок) */

  .ui-slider-horizontal {
    height: 3px;

    /* задаем высоту согласно дизайна */

    .ui-slider-handle {
      top: 50%;
      margin-left: -0px;
      transform: translateY(-50%);

      &:last-child {
        margin-left: -9px;
      }
    }

    .ui-slider-range {
      top: 0;
      height: 100%;
    }

    .ui-slider-range-min {
      left: 0;
    }

    .ui-slider-range-max {
      right: 0;
    }
  }

  /* позиционируем ползунки */

  /* оформление полосы по которой ходит ползунок */

  .ui-widget-content {
    height: 2px;
    background: #acacac;
  }

  /* оформление активного участка (между двумя ползунками) */

  .ui-widget-header {
    background: #7c5cad;
  }

  /* скругление для полосы слайдера */

  .ui-corner-all {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }

  .ui-state-focus {
    outline: none;
  }
}

.filters-resetfilters {
  color: #565c5e;
  font-size: 17px;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  transition-duration: 0.2s;
  margin-bottom: 20px;

  &:hover {
    color: #7c5cad;
    text-decoration: none;
  }
}

.filters-category {
  margin-bottom: 30px;
}

.filters-category-title {
  font-size: 17px;
  margin-bottom: 20px;
}

.filters-category-content-row {
  font-size: 17px;
  transition-duration: 0.2s;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    color: #7c5cad;
  }
}

.filter-checkbox {
  position: relative;
  cursor: pointer;

  &:hover .filter-checkmark {
    border-color: #7c5cad;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    z-index: 5;
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.filter-checkmark {
  position: relative;
  padding-left: 30px;
  display: block;

  &::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    border-width: 1px;
    border-color: rgb(54, 54, 54);
    border-style: solid;
    border-radius: 2px;
    width: 14px;
    height: 14px;
  }
}

.filter-checkbox {
  .filter-checkmark::after {
    content: '';
    position: absolute;
    left: 4px;
    top: 9px;
    width: 4px;
    height: 7px;
    border: solid #7c5cad;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg) translate(-50%, -50%);
    display: none;
    z-index: 5;
  }

  input:checked {
    + .filter-checkmark::after {
      display: block;
    }

    ~ .filter-checkmark {
      &::before {
        border-color: #7c5cad;
      }

      color: #7c5cad;
    }
  }
}

.filters-category-prices-slider {
  width: 180px;
}

.filters-category-prices-inputs {
  margin-bottom: 15px;
  display: flex;

  input {
    box-sizing: border-box;
    width: 85px;
    height: 23px;
    outline: none;
    border-width: 1px;
    border-color: rgb(172, 172, 172);
    border-style: solid;
    border-radius: 2px;
    padding: 3.5px 7px;
    //font-family: myriad;
    font-size: 14px;

    &:first-child {
      margin-right: 10px;
    }
  }
}

.filters-category-content-showmore, .filters-category-content-hide {
  padding-left: 30px;

  a {
    color: #7c5cad;
    font-size: 17px;
    text-decoration: underline;
    transition-duration: 0.2s;

    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}

.filter-button-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  padding-bottom: 15px;
}

.filters-apply {
  box-sizing: border-box;
  border: 1px solid #7d5dad;
  background-color: #7d5dad;
  color: #fff;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  transition-duration: 0.2s;

  &:hover {
    opacity: 0.8;
  }
}

.filters-clear {
  box-sizing: border-box;
  background-color: #fff;
  color: rgba(78, 44, 130, 0.8);
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  transition-duration: 0.2s;
  box-sizing: border-box;

  &:hover {
    color: rgba(78, 44, 130, 0.8);
  }
}

.filters-button-half {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  margin: 0 5px;
}

.filters {
  display: none;
  max-height: 0;
  width: 0;
  padding: 0;
  transition-duration: 0.2s;

  &.active {
    display: block;
    padding: 15px 20px;
    width: 250px;
    max-height: unset;
  }
}

.showfilter-btn.active {
  background-color: rgb(124, 92, 173);

  .showfilter-btn-hover {
    display: block;
  }

  .showfilter-btn-default {
    display: none;
  }
}

.showfilter-btn-mobile, .showfilter-reset-mobile {
  display: none;
}

.filters-closebtn {
  position: relative;
  display: none;

  &::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 3px;
    background-color: #7f5ead;
    transform: rotate(45deg);
    top: 44%;
    left: 19%;
  }

  &::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 3px;
    background-color: #7f5ead;
    transform: rotate(-45deg);
    top: 44%;
    left: 19%;
  }
}

.filters-category-title-arrow {
  position: relative;
  display: none;

  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 1px;
    background-color: #000;
    transform: rotate(45deg);
  }

  &::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 1px;
    background-color: #000;
    transform: rotate(-45deg);
    left: 9px;
  }
}

.floating-button {
  background-color: #7f5ead;
  padding: 15px 30px;
  color: white;
  position: absolute;
  cursor: pointer;

  &:before {
    content: '';
    background-color: #7f5ead;
    width: 25px;
    height: 25px;
    z-index: -1;
    position: absolute;
    transform: rotate(45deg) translateY(-50%);
    right: 0;
    top: 41%;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    width: 100%;
  }

  .head-left {
    width: 100%;
    border-right: none;
    justify-content: flex-start;
  }

  .showfilter-btn {
    display: none;
  }

  .showfilter-btn-mobile, .showfilter-reset-mobile {
    display: block;
  }

  .showfilter {
    font-size: 11px;
    line-height: 11px;
    text-transform: none;
    flex-wrap: wrap;
  }

  .showfilter-btn-mobile {
    font-size: 11px;
    line-height: 11px;
    padding: 3px 20px;
    color: #fff;
    background-color: #7f5ead;
    margin-left: 15px;
    margin-right: 15px;
  }

  .showfilter-reset-mobile {
    color: #7f5ead;
    font-size: 11px;
    padding: 10px 0;
  }

  .content {
    position: relative;
  }

  .content-left {
    width: 100%;
    border: none;
  }

  .category__filter .filters {
    top: 100% !important;
    left: unset !important;
  }

  .filters {
    position: absolute;
    right: 0;
    background-color: rgb(247, 247, 247);
  }

  .filters-category-content-showmore a {
    font-size: 16px;
  }

  .filters {
    &.active {
      padding-left: 10px;
      padding-right: 10px;
      width: 300px;
      padding-top: 50px;
      background-color: #f3f3f3;
    }

    .filters-content {
      max-height: 850px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

  .filters-resetfilters {
    position: absolute;
    top: 18px;

    /*display: none;*/
  }

  .filters-closebtn {
    display: none;
    position: absolute;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    left: 5px;
    top: 5px;

    &.active {
      display: block;
      z-index: 2;
    }
  }

  .filters-clear {
    background-color: transparent;
  }

  .filters-category-title {
    padding-bottom: 8px;
    border-bottom: 1px solid rgb(184, 184, 184);
    font-size: 16px;
  }

  .filters-category-title-arrow {
    display: block;
    left: calc(100% - 20px);
    top: -14px;
  }

  .filters-category-content-row {
    font-size: 22px;
  }

  .filter-checkmark::before {
    width: 18px;
    height: 18px;
    top: 50%;
    transform: translateY(-50%);
  }

  .filter-checkbox {
    input:checked + .filter-checkmark::after {
      left: 6px;
    }

    .filter-checkmark::after {
      top: 50%;
    }
  }

  .filters-category-prices-inputs input {
    width: 50%;
  }

  .filters-category-prices-slider {
    width: unset;
  }

  .filters-category-content {
    max-height: 0;
    overflow: hidden;
  }

  .filters-category-title {
    margin-bottom: 0;
  }

  .filters-category {
    margin-bottom: 12px;
  }

  .filters-category-prices-inputs {
    margin-bottom: 10px;
  }

  .filters-category.active {
    .filters-category-content {
      max-height: unset;
      padding-top: 25px;
      padding-bottom: 12px;
      border-bottom: 1px solid rgb(184, 184, 184);
      transition-duration: 0.4s;
    }

    .filters-category-title-arrow {
      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }
  }

  .filter-button-row {
    display: none;
  }

  .filters-apply {
    margin-left: auto;
    margin-right: auto;
    width: 130px;
    padding: 0;
    font-size: 18px;
  }
}

.mobile-position, .mobile-visible {
  display: none;
}

@media screen and (max-width: 1000px) {
  .mobile-position {
    display: flex;
    margin-top: 5px;
  }

  .desktop-position {
    display: none;
  }

  .mobile-visible {
    display: block;

    &.filters-closebtn {
      color: rgba(129, 84, 197, 0.8);
      font-size: 14px;
    }
  }

  .filters-closebtn {
    &::before, &::after {
      content: none;
    }
  }
}