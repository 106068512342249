.row__delivery {
    padding: 0 35px;
}

#shipping_address {
    padding: 0 38px;
}

#id_shipping,
#id_payment {
    margin: 0;
    margin-bottom: 20px;
    padding: 0;
    list-style: none;

    input {
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(4px);
    }

    label {
        position: relative;
        padding-left: 35px;
    }

    li {
        margin-bottom: 30px;
    }

    li:last-child {
        margin-bottom: 0;
    }
}

#id_shipping {
    margin-bottom: 30px;
    li.sub-title {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
}

.manager-info {
    font-size: 0.875rem;
    display: inline-block;
    margin-bottom: 13px
}

//.shipping_address__wrapper {
//    min-height: 55px;
//    clear: both;
//}

.payment-kassa {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 525px;
    margin: 0 auto;

    .content-inner__title {
        text-align: center;
        margin-bottom: 20px;
    }
}

#cart-checkout-form,
#simple-reg-form {
    
    .form-buttons,
    .form-buttons__inner {
        display: flex;
        justify-content: space-between;
    }
    .form-buttons__inner {
        button {
            width: 120px;
            height: 40px;
        }
    }
    .back {
        display: flex;
        width: 100%;
        font-size: 14px;
        margin-left: 20px;
        margin-top: 10px;
    }
    .cart-simple-reg-checkout-btn {
        margin-bottom: 10px;
        padding: 5px 10px;
        font-size: .8rem
    }
    span.blink {
        display: none;
    }
    input[type=radio]:checked + span.blink {
      display: block;
      font-weight: bold;
    }
    .select-tk {
        display: inline;
        width: 224px;
        option:first-child {
            color: gray;
        }
    }
}

.payment__form {
    .form-submit {
        border: 0;
        background: none;
        color: #5c369b;
        padding: 0;
        margin: 10px 0;
        font-size: 14px;
        &:hover {
            text-decoration: underline;
        }
    }
}

#id_shipping {
    .payment-logo {
        width: 95px;
        height: 20px;
        position: relative;
        top: -4px;
    }

    li {
        position: relative;
    }

    a {
        position: absolute;
        bottom: -22px;
        left: 35px;
    }
    
}


.wrapper--cart {
    .payment-logo {
        width: 95px;
        height: 20px;
        position: relative;
        top: -4px;
        left: 5px;
    }
}



