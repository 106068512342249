.mfp-place-gallery {
  .bx-wrapper .bx-viewport {
    box-shadow: none;
  }
}

.single-product {
  border-radius: 3px 3px 0 0;
  //border: 1px solid $border-color;
  border: 0;
  //margin-bottom: 20px;
  &__title {

    border: 1px solid $border-color;
    border-top: 0;
    border-bottom: 0;
    background-color: $white;
    padding: 10px;
    color: rgba(92, 52, 153, 0.8);
    font-family: "HelveticaNeue";
    font-size: 1rem;
    margin: 0;

    span {
      margin-left: 5px;
      color: rgb(57, 64, 66);
    }
  }

  &__body {
    padding: 10px;
    border: 1px solid $border-color;
    border-top: 0;
  }

  &__gallery {
    display: flex;
    justify-content: space-between;

    .orbit-container {
      flex: 2;
    }
  }

  &__btns {
    margin: 10px 0;

    input[type="radio"] {
      display: none;
    }

    input[type="radio"] + label {
      display: inline-block;
      border: 1px solid #ccc;
      padding: 6px 10px;
      transition: .2s;
      font-size: 10px;
      vertical-align: top;
    }
  ;

    .label_l {
      border-bottom-left-radius: 25px;
      border-top-left-radius: 25px;
      margin-left: 0;
      margin-right: 0;
    }

    .label_r {
      border-bottom-right-radius: 25px;
      border-top-right-radius: 25px;
      margin-left: -6px;

    }

    input[type="radio"]:checked + label {
      background: #ef8024;
      color: #fff;
    }

    .add-to-cart {
      display: inline-block;

      input {
        width: 50px;
        height: 28px;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        margin: 0;
      }

      button {
        border: none;
        background-color: #5c369b;
        color: #ffffff;
        padding: 5px 10px;
        height: 28px;
        margin-left: -5px;
        font-size: 13px;
        transition: background-color 300ms ease-out 0s;

        &:hover {
          background-color: #4e2e82;
        }
      }
    }
  }

  &__character {
    margin-bottom: 10px;
  }

  &__description-title {
    font-weight: bold;
  }

  .character__list {
    list-style: none;

    .character__item {
      display: flex;
      align-items: center;
      @include clearfix;
      @media #{$large-up} {
        font-size: 13px;
      }
    }

    .character__title {
      float: left;
      width: 40%;
      @media screen and (max-width: 400px) {
        font-size: 14px;
      }
      @media #{$large-up} {
        width: 115px;
      }
    }

    .character__description {
      float: left;
      padding-left: 5px;
      width: 60%;
      color: #555;
      @media screen and (max-width: 400px) {
        font-size: 14px;
      }
      @media #{$large-up} {
        width: 120px;
      }
    }

    .character__description-articul {
      font-family: "Helvetica";
      font-size: 15px;
      font-weight: 600;
    }
  }

  &__img {
    margin: 0 auto;
  }

  &__gallery-thumb {
    overflow-y: auto;
    max-height: 360px;
    padding-right: 5px;

    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 80px;

    a {
      display: inline-block;
      //margin-left: 10px;
      margin-bottom: 10px;
    }
  }

  &__gallery-thumb::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    background-color: #F5F5F5;
  }

  &__gallery-thumb::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
  }

  &__gallery-thumb::-webkit-scrollbar-thumb {
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(54, 54, 54, 0.3);
    background-color: #bdbdbd;
  }

  &__text {
    text-align: justify;
    margin-bottom: 20px;
    @media #{$large-up} {
      padding: 0 20px;
      font-size: 14px;
      line-height: 16px;
    }
  }
}