.bottom-index {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    height: 100%;
    p {
        font-family: "Helvetica";
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.6;
        margin-bottom: 1.25rem;

    }
    ul {
        margin-left: 20px;

    }
    h1 {
        font-size: 26px;
        margin: 0;
        padding-top: 10px;

    }
    h2 {
        font-size: 22px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 18px;
    }
}
.wrapper-content-bottom, .wrapper-content-bottom .columns {
    height: 100%;
}