.ya-labels {

  .ya-labels__ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .ya-labels__li {
    display: inline-block;
    text-align: center;
  }

  .ya-labels__item {
    display: block;
    //min-width: 46px;
    min-width: 2.5rem;
    padding: 2px 5px;
    min-height: 10px;
    border-radius: 6px;
    //font-size: 14px;
    font-size: .7rem;
    font-family: $font-medium;
    line-height: 14px;

  }

  .ya-is-new, .ya-is-new:hover {
    color: $green;
    border: 1px solid $green;
    text-decoration: none;

  }

  .ya-is-hit, .ya-is-hit:hover {
    color: $orange;
    border: 1px solid $orange;
    text-decoration: none;
  }

  .ya-is-discount, .ya-is-discount:hover {
    color: $red;
    border: 1px solid $red;
    text-decoration: none;
  }
}

.f-product__right {
  .ya-labels {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 4px;

  }
}

.list-greed .f-product__right {
  height: auto !important;

  .ya-labels {
    width: 100%;
    position: relative;
    bottom: 0;
    left: 0

  }
}

$labelPath : "../img/new-hit-5.png";

.cat-label__wrapper {
  margin-right: 3px;
  height: 10px;

  &.cat-new-label.cat-sale-label.cat-pop-label {
    background: url($labelPath) no-repeat 0 0 !important;
    width: 69px;
    min-width: 69px;
    &:before, &:after {
      display: none !important;
    }
  }

  &.cat-new-label.cat-sale-label {
    background: url($labelPath) no-repeat 0 0;
    width: 41px;
    min-width: 41px;
  }

  &.cat-new-label.cat-pop-label {
    width: 50px;
    min-width: 50px;
    background: none !important;

    &:before {
      content: "";
      background: url($labelPath) no-repeat 0 0;
      display: inline-block;
      width: 28px;
      height: 10px;
    }

    &:after {
      content: "";
      background: url($labelPath) no-repeat -47px 0;
      display: inline-block;
      width: 22px;
      height: 10px;
    }
  }

  &.cat-sale-label.cat-pop-label {
    background: url($labelPath) no-repeat -24px 0;
    width: 46px;
    min-width: 46px;
  }

  &.cat-new-label {
    background: url($labelPath) no-repeat 0 0;
    width: 25px;
    min-width: 25px;
  }

  &.cat-sale-label {
    background: url($labelPath) no-repeat -29px 0;
    width: 12px;
    min-width: 12px;
  }

  &.cat-pop-label {
    background: url($labelPath) no-repeat -47px 0;
    width: 25px;
    min-width: 25px;
  }
}