.seo-link {
  padding: 0 1rem;
  li {
    list-style-type: none;
    display: inline-block;
    &.active {
      a.button.inverse {
        color: $white;
        background-color: #bba9d2;
      }
    }
    .button {
      margin: 0;
    }
  }
}