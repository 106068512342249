.white-popup-block {
  background-color: #F5F5F5;
  text-align: left;
  max-width: 650px;
  margin: 40px auto;
  position: relative;

  .message {
    background-color: #48ae60;
    color: #fff;
    padding-left: 40px;
    line-height: 50px;
    height: 50px;
  }
  .checkout-comment {
    width: 88%;
    min-height: 100px;
    margin: 0 auto;
    margin-bottom: 10px; 
    
  }

  .magnific-dismiss, .mgp-dismiss {
    color: rgba(92, 52, 153, 0.8);
    text-decoration: underline;
    background: transparent;
    border: none;
    font-size: 0.8rem !important;
    display: inline-block;
    margin: 0;

  }

  // .magnific-dismiss, .mpg-dismiss {
  //   color: rgba(92, 52, 153, 0.8);
  //   text-decoration: underline;
  //   background: transparent;
  //   border: none;
  //   font-size: 0.7125rem;
  //   display: inline-block;
  //   margin: 0;
  //  }
    .cart-checkout-btn,
    .cart-clear-btn,
    .cart-payment-btn {
    display: inline-block;
    padding: .4rem;
    border-radius: 3px;
    border: 1px solid rgba(78, 44, 130, 0.8);
    background-color: rgba(92, 52, 153, 0.8);
    color: #fff;
    transition: background-color 300ms ease-out;
    font-size: .8rem;
    margin-right: 20px;
    margin-bottom: 10px !important;
    }
    .message-top {
      font-size: 1rem;
      padding: 5px 0;
      color: rgba(92, 52, 153, 0.8);
    }
    .message-small {
      padding: 5px 0;
      font-size: .7rem;
      color: #000;
    }
    .mfp-close {
        color: #fff !important;
    }
    ::-webkit-input-placeholder {color:#b1b1b1;}
    ::-moz-placeholder          {color:#b1b1b1;}/* Firefox 19+ */
    :-moz-placeholder           {color:#b1b1b1;}/* Firefox 18- */
    :-ms-input-placeholder      {color:#b1b1b1;}
}

.white-popup-block.white-popup-block_is-first {
  padding: 25px 30px 15px 30px;
  max-width: 620px;
  .message {
    display: none;
    background-color: #e6e6e6;
  }
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.85);
  .mfp-close {
    font-size: 2rem;
    //color: #8e979a !important;
    color: #394042 !important;
  }
  .sign {
    padding: 10px; 
    font-size: 2em;
  }
  .price-list {
    padding: 10px;
    font-size: 1rem;
    a {
      color: #394042;
    }
    .columns {
      a {
        display: block;
      }
      a:nth-child(even) {
        background-color: lightgrey;
      }
    }
  }
  .message-top {
    margin-bottom: 0;
    color: #394042;
    line-height: 1.8;
    span {
      font-weight: 900 !important;
      font-family: Helvetica, Arial, sans-serif;
    }

  }
  .message-small {
    font-size: 1rem;
    color: #394042;
    line-height: 1.5;
    margin-bottom: 15px;
    span {
      font-weight: 900 !important;
      font-family: Helvetica, Arial, sans-serif;
    }
  }

} 
.white-popup-block.cart-checkout {
  //min-height: 584px;
  padding: 0;
  .message-enter {
    padding: 0 25px;
  }
  .row {
    margin-bottom: 10px;
  }
  .row__delivery {
    position: relative;
    padding-bottom: 32px;
  }
  .row__payment {
    #id_payment li {
      margin-bottom: 20px;
    }
    label {
      position: relative;
      .form__error {
        position: absolute;
        bottom: -13px;
        left: 15px;
        min-height: 0;
        height: auto;
        margin-bottom: 0;

      }
    }
  }
  #simple-reg-form {
    padding: 0 10px;

    .row.row__delivery {
      padding: 0;
      #id_shipping {
        //padding-bottom: 30px;
        input{
          top: 50%;
          left: 0;
          transform: translateY(-8px);
        }
      }
    }
  }
  #id_shipping {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      position: relative;
      padding-left: 25px;
      padding-bottom: 20px;
      margin-bottom: 5px;
      input {
        position: absolute;
        display: block;
        margin: 0;
        top: 4px;
        left: 0;
      }
      a {
        font-size: 15px;
        position: absolute;
        bottom: -1px;
        left: 24px;
      }

      #about_contact {
        bottom: -2px;
        left: 60px;
      } 

      .select_pickpoint {
        bottom: -22px;
        left: 35px;
      }
    }
  }
  //#shipping_address {
    //position: absolute;
    //bottom: 0;
    //left: 0;
    //width: 98%;
    //label, input {
    //  margin: 0;
    //}
  //}
  .checkout-comment {
    width: 100%;
  }
  #id_payment {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 10px;
    }
    input {
      margin: 0;
    }
  }
}
.white-popup-block.popup-success {
  .inner-text {
    margin: 0 40px;
    padding-bottom: 10px;
    p {
      margin-bottom: 10px;
      font-size: 10px;
    }
  }
  button {
      margin-bottom: 4px;
      font-size: 11px;
    }
}
.white-popup-block.white-popup-block_first-enter {
  max-width: 345px;
  padding: 20px 20px 0 20px;
  position: relative;
  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    top: 53%;
    left: 0;
    background-color: #ddd9d9;
  }
  .message {
    background-color: transparent;
    margin-bottom: 15px;
    font-family: "HelveticaNeue", Helvetica, Roboto, Arial, sans-serif;
    //color: #394042;
    color: #202223;
  }
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.85);
  button {
    border-radius: 0;
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-family: "HelveticaNeue-Bold", Helvetica, Roboto, Arial, sans-serif;
    //color: #394042;
    color: #202223;
    &:hover {
      color: #676b6d;
    }
  }
  .mfp-close {
    color: #394042 !important;
  }
}

#cart-checkout {
    display: inline-block;
    position: relative;
    .white-popup-block .message{
      padding: 0 45px 0 26px;
      font-size: 15px;
    }
    .mfp-close {
      top: 40px;
      color: #fff;
    }
}

.white-popup-block .mfp-close {
  font-size: 28px !important;
  font-family: Arial, Baskerville, monospace !important;
}

