.footer {
  background-color: #E6E6E6;
  font-size: 11px;
  min-height: 103px;
  padding-top: 10px;
  &__copyright {
    text-align: center;
  };
  &__logo {
    text-align: right;
  };
  @media #{$medium-up} {
    padding-top: 0;
    min-height: 33px;
    &__copyright {
      text-align: left;
      padding-top: 8px;
    };
  };
  @media #{$large-up} {
    min-height: 60px;
    &__copyright {
      text-align: right;
      margin-top: 15px;
    }
  }
}

.footer-menu {
  margin-bottom: 10px;

  &__list {
    list-style: none;
    display: inline-block;
  };

  &__item {
    display: inline-block;
  }

  &__link {
    text-transform: uppercase;
    color: black;
    font-size: 11px;
    display: inline-block;
    vertical-align: top;
    text-decoration: underline;

  };
  &__link:hover {
    text-decoration: none;

  }

  @media #{$medium-up} {
    margin-bottom: 0;
    &__link {
      padding: 8px 0  ;
    };
    &__item {
      margin-left: 2px;
      &:first-child {
        margin-left: 0;
      }
    };
  }
  @media #{$large-up} {
    &__list {
      margin-top: 15px;
    }
    &__item {
      margin-left: 15px;
    }
  };
}
span.footer-menu__link {
  text-decoration: none;
}
.socials-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  margin-left: 15px;
  margin-top: 18px;
  @media screen and (max-width: 1024px) {
    margin-top: 8px;
    margin-left: 0px;
  }
  @media screen and (max-width: 640px) {
    margin-top: 8px;
    width: 100%;
    text-align: center;
  }

  .socials-list__item {
    display: inline-block;
    margin-bottom: 10px;
  }
  .socials-list__link {
    display: block;
    text-decoration: none;
    width: 25px;
    height: 20px;
  }
  .socials-list__item:first-child {
      margin-right: 0px;
     .socials-list__link {
        width: 45px;
     }
  }
  
  .socials-list__img {
    width: 100%;
    height: auto;
  }
}