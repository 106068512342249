.news__list {
  list-style: none;
  padding: 0;
  margin: 0 0 5px 0;

  .news__item {
    overflow: hidden;
    margin-bottom: 20px;
  }
}

.news__title {
  float: left;
}

.news__datetime {
  float: right;
  font-size: 11px;
  font-weight: 700;
}

.news__text {
  float: left;
  width: 100%;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px;
}

.news-inner {
  @include clearfix();
}

.news__img {
  float: left;
  width: 50px;
  height: 50px;
}

.news__description {
  float: left;
  @media #{$large-up} {
    width: 140px;
  }
}

.button-news {
  display: block;
  text-align: center;
  border: 1px solid #e3e3e3;
  padding: 7px;
  font-size: 13px;
  background-color: #f7f7f7;
  margin-bottom: 15px;
  color: #555;
  font-weight: 300;
}