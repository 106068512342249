.new-menu {
  .accordion {
    width: 100%;
    max-width: 360px;
    margin: 0px auto 20px;
    background: #FFF;
    border-radius: 4px;
    list-style: none;
  }

  .link {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0 13px 0 17px;
    color: #6b6b6b;
    font-size: 16px;
    font-family: "HelveticaNeue", "Helvetica Neue", "Helvetica";
    font-weight: 400;
    border-bottom: 1px solid #CCC;
    position: relative;
    transition: all 0.4s ease;
    line-height: 14px;
    height: 45px;
    @media screen and (max-width: 1020px) {
      font-size: 12px;
    }

    &:hover {
      background: rgba(115,77,172,0.23);
      .cat-label__wrapper {
        text-decoration: none;
      }
    }
    
  }
  .link.mark {
    background: #efefef;
    //font-family: "Helvetica";
    font-weight: 700;

    &:hover {
      background: rgba(115,77,172,0.23);
    }
  }
  
  .link a {
    color: #6b6b6b;
    text-decoration: none;
    display: flex;
    flex: auto;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    span {
      flex: 1 1 auto;
    }
    &:hover {
      //text-decoration: underline;
    }
  }

  .link.active a {
    color: #FF0000 //
  }
  li:last-child .link { border-bottom: 0; }

  li i {
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-5px);
    font-size: 10px;
    color: #595959;
    transition: all 0.4s ease;
  }

  li i.glyphicon-chevron-down {
    right: 3px;
    left: auto;
    font-size: 10px;
    z-index: 99;
  }

  li.open .link.active { 
    background: #fff;

    &:hover {
      background: rgba(115,77,172,0.23);
    }
  }
  li.open .link { 
    background: #efefef;
  }  
  //li.open > .link a { color: #FF0000 } 

  li.open .link.active a{ color: #FF0000 }

  //li.top-active > .link a{ color: #FF0000 }
  li.top-active.open > .link {
    background: #efefef;

    &:hover {
      background: rgba(115,77,172,0.23);
    }
  }

  //li.top-active.open > .link.link-top {
  li.top-active > .link.link-top {  
    a, i {
      color: #FF0000;
    }
  }
  //li.open i { color: #FF0000; }
  .link.active i{ color: #FF0000 }

  li.open i.glyphicon-chevron-down {
    &:before {
      content: "\e113" !important;
    }
    //transform: rotate(180deg);

  }

  li.mark {
    background: #efefef;

    &:hover {
      background: rgba(115,77,172,0.23);
    }

    &:hover .link {
      background: rgba(115,77,172,0) !important;
    }

    a {
      //font-family: "Helvetica";
      font-weight: 700;
    }
  }

  /**
   * Submenu
   -----------------------------*/
  .top-active.open {
    .submenu {
      display: block;
    }
  }

  .submenu {
    display: none;
    //background: #444359;
    background: #efefef;
    list-style: none;
    margin: 0;
    @media screen and (max-width: 1020px) {
      font-size: 11px;
    }
  }
  .submenu .link {
    font-size: 12px;
    font-family: "HelveticaNeue", "Helvetica Neue", "Helvetica";
    font-weight: 400;
    line-height: 12px;
    height: 30px;

    &:first-child {
      border-bottom: none;
    } 

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: rgba(115,77,172,0.23);
    }
  }
  .submenu li .link.active { 
    background: #efefef !important;
  }
  .submenu li.open .link.active { 
      background: #efefef !important;

    i.glyphicon-chevron-down {
      display: none !important;
    }
  }

  .submenu li.open .link.active:hover {
    background: rgba(115,77,172,0.23) !important;
  }

  .submenu li { border-bottom: 1px solid #CCC; }
  
  .submenu li .link { color: #4D4D4D }
  .submenu li.open .link a { color: #FF0000 }//
  
  .submenu li i { color: #595959 }  
  .submenu li.open i { color: #FF0000 }
  .submenu li i.glyphicon-chevron-down {
    display: none !important;
    transform: none ;
    color: #6b6b6b;
  }

  .submenu li.open i.glyphicon-chevron-down {
    display: none;
    transform: rotate(180deg) ;
    color: #FF0000;
  }

  .submenu a {
    display: flex;
    flex: auto;
    align-items: center;
    text-decoration: none;
    color: #4D4D4D;
    //padding: 12px;
    //padding-left: 42px;
    //padding: 10px 15px 10px 17px;
    transition: all 0.25s ease;
  }

  .submenu a:hover {
    //background: #FF0000;
    //text-decoration: underline;
    //color: #FFF;
  }

  .submenu .submenu{
    background: #FFF;
    a { 
      color: #4D4D4D;
    }
    a:hover {
      text-decoration: underline;
    }

    li .link { color: #4D4D4D }
    li i { color: #4D4D4D; }
    li.open i { color: #FF0000; }
    li.open .link a { color: #FF0000 } //
    li i.glyphicon-chevron-down { transform: none ; color: #4D4D4D;}
    li.open i.glyphicon-chevron-down { transform: rotate(180deg) ; color: #FF0000;}

    li {
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .submenu .submenu .submenu{
    background: rgba(115,77,172,0.38);
    a { 
      color: #4D4D4D;
    }
    a:hover {
      text-decoration: underline;
    }

    li .link { color: #4D4D4D }
    li i { color: #4D4D4D; }
    li.open i { color: #FF0000; }
    li.open .link a { color: #FF0000 } //
    li i.glyphicon-chevron-down { transform: none ; color: #4D4D4D;}
    li.open i.glyphicon-chevron-down { transform: rotate(180deg) ; color: #FF0000;}

    li {
      &:last-child {
        border-bottom: none;
      }
    }
  }
}