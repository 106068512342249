.f-product__item--search > .row {
  display: flex;
  margin: 0;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }

  .f-product__item-left {
    width: 25%;
    padding: 0 5px;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .f-product__item-center {
    width: 50%;
    padding: 0 5px;
    @media screen and (max-width: 768px) {
      width: 48%;
    }
    @media screen and (max-width: 425px) {
      width: 100%;
    }

    .f-product__description {
      width: 100%;
    }

    .f-product__art {
      font-style: .62rem;
    }

    .f-product__title {
      .f-product__title-link {
        font-size: 1rem;
      }
    }

    .f-product__text {
      font-size: .86rem;
    }

    .f-product__btns {
      @media screen and (max-width: 420px) {
        text-align: center;
      }
    }
  }

  .f-product__item-right {
    width: 25%;
    padding: 0 5px;
    @media screen and (max-width: 768px) {
      width: 40%;
    }
    @media screen and (max-width: 425px) {
      width: 100%;
    }

    .f-product__price_l {
      font-size: .86rem;
    }

    .f-product__price_sum {
      font-size: 1rem;
    }

    .f-product__stock {
      font-size: .8rem;

      &.without_sale {
        color: #c8c7c7;
        font-size: 10px;
      }
    }

    .ya-labels__ul {
      display: block;
      text-align: center;
      @media screen and (max-width: 768px) {
        text-align: left;
      }
      @media screen and (max-width: 640px) {
        text-align: center;
      }
    }

    .ya-labels .ya-labels__item {
      font-size: .87rem;
      min-width: 3rem;
      line-height: 1;
    }
  }
}