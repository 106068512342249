.tabs__item img {
  height: 40px;
  width: 40px;
  margin-top: 10px;
}

.mfp-tab {
  display: none;

  &.active {
    display: block;
  }
}


.image-source-link {
  color: white;
  text-decoration: underline;

  &:visited {
    color: white;
  }
}

.pw-show-hide {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 2px;
  padding: 0 10px;
  font-size: 20px;
  color: gray;
}