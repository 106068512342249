.registration-top {
  height: 27px;
  background-color: $top-menu;
  margin-bottom: 50px;
  @media #{$large-up} {
    .help {
      margin-top: 0;
    };
  }
}

.logo {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 25px;
  width: 235px;
  img {
    width: 100%;
  }
}

.registration__form {
  margin-bottom: 20px;
  border-radius: 3px;
  background-color: rgba(255,255,255,0.7);
  @media #{$medium-up} {
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.5)
  }
  .button {
    font-size: 12px;
    padding: 12px 0;
  }
}
.form__header {
  padding: 20px;
}
.form__body {
  padding: 0 20px;
}

.form__footer {
  @media #{$small-only} {
    margin: 0 -1.9375rem;
  }
}
.sign-in {
  height: 45px;
  width: 100%;
  background-color: #F2F2F2;
  display: block;
  text-align: center;
  font-size: 14px;
  font-family: $font-regular;
  line-height: 45px;
  border-radius: 0 0 3px 3px;
}

// Форма входа

.form__error {
  font-size: 12px;
  color: red;
  min-height: 40px;
  text-align: justify;
  word-wrap: break-word;
  line-height: 1.5;
  margin-bottom: 10px;
  word-spacing: 0.3em;
  // word-wrap: break-word;
  // word-break: break-all;
  

}

.form__forget {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  color: scale-color($primary-color, $lightness: -30%);
  text-align: right;
}

.form__forget:hover {
  color: scale-color($primary-color, $lightness: -15%);
  text-decoration: underline; 
}