body {
  font-family: $font-regular, Helvetica, Roboto, Arial, sans-serif;
  color: $body;
  font-size: 62.5%;
}

.container {
  margin: 0 auto;
  position: relative;
  @media #{$medium-up} {
    max-width: 1068px;
  }
  @media #{$large-up} {
    max-width: 1100px;
  }
}

.wrapper {
  min-height: 100vh;
  margin-bottom: -103px;

  &:after {
    content: '';
    display: block;
    height: 103px;
  }
;
  @media #{$medium-up} {
    margin-bottom: -33px;
    &:after {
      height: 33px;
    }
  };
  @media #{$large-up} {
    margin-bottom: -60px;
    &:after {
      height: 60px;
    }
  }
}

ul {
  margin: 0;
  padding: 0;
}

.font-light {
  font-family: $font-light;
}

.help {
  font-size: 14px;
  line-height: 15px;
  vertical-align: top;
  top: 0px;
  display: inline-block;
  position: relative;
  font-family: $font-light;

  span {
    display: block;
  }

  @media screen and (max-width: 450px) {
    & > span {
      display: none;
    }
  }

  &:before {
    position: absolute;
    content: '';
    width: 14px;
    height: 14px;
    display: inline-block;
    left: -20px;
    top: 1px;
    background-image: url(../img/icons/help.svg);
    background-repeat: no-repeat;
  }
}

.no-svg .help {
  &:before {
    background-image: url(../img/icons/sprite1.png);
    background-position: right 0;
  }
}

.off-canvas-wrap {
  background: #f7f7f7;
}

ul.off-canvas-list li a {
  transition: none;
}

.main-section {
  background-color: #fff;
  //background: url("../img/patterns/boxes1.jpg") repeat;
  background-size: 350px 350px;
  background-attachment: fixed;
  margin-top: 35px;
}

.bx-window {
  border: 1px solid #e3e3e3;
}

.catalog_widget, #panel1, #panel2, #panel3, #panel4, #panel5, .single-product__body, .f-product__list, .cart-sidebar, .tabs-content {
  background-color: #fff;
}

.tabs-content > .content {
  padding: 0;
}

header {
  background-color: rgba(255, 255, 255, 0.5);

  .header__bottom {
    @media #{$large-up} {
      display: none;
    }
    margin-bottom: 5px !important;
  }

  @media screen and (min-width: 1024px) {
    .medium-6 {
      width: 54%;
    }
    .medium-3 {
      width: 23%;
    }
  }

  .row:first-child {
    padding-top: 15px;
    @media screen and (max-width: 1024px) {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}

.enter-links__item {
  display: inline-block;
  position: relative;
  font-size: 14px;
  line-height: 15px;
  // margin-left: 30px;
  font-family: $font-light;
  top: 2px;
  @media screen and (max-width: 425px) {
    font-size: 14px;
  }
  @media screen and (max-width: 375px) {
    font-size: 9px;
  }

  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 22px;
    height: 17px;
    left: -20px;
    top: 2px;
    background-repeat: no-repeat;
  }

  &_login {
    &:before {
      background-image: url(../img/icons/key.svg);
    }
  }

  &_reg {
    &:before {
      background-image: url(../img/icons/user.svg);
    }
  }

  &_retail {
    font-family: "HelveticaNeue";
    font-weight: 700;

    &:before {
      background: url(../img/icons/left-arrow.png) center center no-repeat;
      background-size: cover;
      width: 8px;
      height: 8px;
      left: -14px;
      top: 4px;
    }
  }
}

.no-svg .enter-links__item_login {
  &:before {
    background-image: url(../img/icons/sprite1.png);
    background-position: left 0;
  }
}

.no-svg .enter-links__item_reg {
  &:before {
    background-image: url(../img/icons/sprite1.png);
    background-position: -63px 0;
  }
}

.login-btn {
  width: 100%;
  border: none;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 15px;
}

// Top Menu

.tab-bar {
  background: #f7f7f7;
  height: 60px;
  line-height: 60px;

  .menu-icon {
    height: 60px;

    span::after {
      box-shadow: 0 0 0 1px black, 0 7px 0 1px black, 0 14px 0 1px black;
    }
  }

  @media #{$medium-up} {
    height: 20px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}

.tab-bar-section.right {
  left: initial;
}

.tab-bar-section.left {
  left: 50px;
}

.tab-bar-section.middle {
  right: 7rem;
  left: 7rem;
}

.top-modile__link {
  display: inline-block;
  width: 22px;
  height: 17px;
  margin-left: 15px;
  background-repeat: no-repeat;

  &_contact {
    background-image: url(../img/icons/phone.svg);
  }

  &_search {
    background-image: url(../img/icons/loupe.svg);
  }

  &_cart {
    background-image: url(../img/icons/cart.svg);
  }
}

.no-svg .top-modile__link_contact {
  background-image: url(../img/icons/sprite1.png);
  background-position: -129px 0;
}

.no-svg .top-modile__link_search {
  background-image: url(../img/icons/sprite1.png);
  background-position: -197px 0;
}

.no-svg .top-modile__link_cart {
  background-image: url(../img/icons/sprite1.png);
  background-position: -265px 0;
}

.header__logo {
  width: 100px;
  margin: 0 auto;
}

.left-small {
  border-right: none;
}

.top {
  margin-top: 1px;
}

.top__cart, .top__profile {
  font-size: 14px;
  line-height: 15px;
  font-family: $font-light;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 22px;
    height: 22px;
    display: inline-block;
    left: -27px;
    top: -2px;
    background-repeat: no-repeat;

  }

}

.top__cart:before {
  left: 0;
  top: -4px;
  @media screen and (max-width: 640px) {
    left: -25px;
  }
}

.top__cart {
  display: inline-block;
  width: 20px;
  height: 10px;

  span {
    display: block;

    &.badge {
      position: absolute;
      background: white;
      color: red;
      font-size: 10px;
      font-weight: bold;
      width: 18px;
      height: 18px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid #5c369b;
      padding: 0;
      top: -7px;
      left: -11px;
      line-height: 16px;
    }
  }

  @media screen and (max-width: 530px) {
    & > span {
      display: none;

      &.badge {
        display: block;
      }
    }
  }
  @media screen and (min-width: 530px) {
    top: 3px;
  }
  @media screen and (min-width: 638px) {
    top: 3px;
    & > span {
      display: none;
    }
  }
}

.top__profile {
  top: 2px;
  //margin-right: 35px;
  &:before {
    background-image: url(../img/icons/cabinet.svg);
    background-position: 5px 4px;
  }
}

.no-svg .top__profile {
  &:before {
    background-image: url(../img/icons/sprite1.png);
    background-position: 0 -30px;
  }
}

.top__cart {
  &:before {
    background-image: url(../img/icons/cart.svg);
    background-position: 0 2px;
  }
}

.no-svg .top__cart {
  &:before {
    background-image: url(../img/icons/sprite1.png);
    background-position: -265px 0;
  }
}

.breadcrumbs {
  margin-top: 0;
  //margin-bottom: 15px;
  padding-bottom: 0.25rem;
  border-radius: 0;
  //border: 1px solid $border-color;
  border: 0;
  border-bottom: 1px solid #ddd3ec;
  border-left: 1px solid #ddd3ec;
  border-right: 1px solid #ddd3ec;
  //margin-bottom: 5px;
  //border-bottom: 1px solid scale-color($primary-color, $lightness: -15%);
  background-color: $white;
  color: $white;

  &__item a {
    color: #6b6b6b;
  }

  & > *.current {
    color: #6b6b6b;
  }

  & > *.current a {
    color: #6b6b6b;
  }

  li {
    margin-bottom: 5px;
  }

  @media screen and (max-width: 447px) {
    // margin-top: 50px;
  }
}

.content-inner {
  border-radius: 3px 3px 0 0;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  //margin-bottom: 15px;
  height: 100%;
  position: relative;

  h1 {
    font-size: 26px
  }

  h2 {
    font-size: 22px
  }

  h3 {
    font-size: 20px
  }

  h4 {
    font-size: 18px
  }

  &__title {
    background: $white;
    padding: 0.5rem 1rem;
    border-radius: 3px 3px 0 0;
    //border-left: 1px solid $border-color;
    //border-right: 1px solid $border-color;
    color: scale-color($primary-color, $lightness: -15%);
    font-size: 1rem;
  }

  &__date {
    position: absolute;
    right: 12px;
    font-size: 11px;
    font-weight: 700;
    top: 45px;
  }

  &__body {
    padding: 0.5rem 1rem;
    background-color: $white;
    overflow-y: auto;
  }

  &__body.flatpage {
    //border-left: 1px solid $border-color;
    border-top: 0;

    a {
      font-family: "Helvetica";
      font-weight: 700;
      font-size: 18px;
    }

    ul {
      margin-left: 20px;
    }
  }

  .breadcrumbs {
    border-left: 0;
    border-right: 0;
    list-style: none;
  }
}

.single-product__video,
.f-product__video {
  background-image: url(../img/icons/play.svg);
  background-repeat: no-repeat;
  background-position: left 50%;
  padding-left: 19px;
  margin: 5px 0;
  font-size: 14px;
  display: inline-block;
}

.f-product__video {
  margin: 15px 0px 0;
  // background-position-x: 115px;
  display: block;
  font-size: .8rem;
  vertical-align: center;
  max-width: 125px;
}

button, .button, .btn {
  border-radius: 3px;
  border: 1px solid scale-color($primary-color, $lightness: -15%);
  background-color: $primary-color;
  color: $white;
  transition: background-color 300ms ease-out;
  margin-right: 5px;
  padding: .5rem 1rem .5rem 1rem;
}

button:hover, .button:hover, .btn:hover, .btn:focus {
  background-color: scale-color($primary-color, $lightness: 7.5%);
  color: $white;
  transition: background-color 300ms ease-out;
}

.bright {
  color: #d31f26;
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.pagination-wrap {
  //margin: 1rem auto;
  margin: 0rem auto;
}

.pagination {
  display: inline-block !important;
}

a {
  text-decoration: none;
  color: #5c369b;
  outline: none;
}

a:hover, a:focus {
  text-decoration: underline;
  color: #5c369b;
}

.nowrap {
  white-space: nowrap;
}

.label_new {
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #008055;
  padding: 1px 10px 2px 10px;
  min-width: 100px;
  background-color: transparent;
  color: #008055;
  font-size: 13px;
  line-height: 13px;
  margin-top: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label_discount {
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #cc2126;
  padding: 1px 10px 2px 10px;
  min-width: 100px;
  background-color: transparent;
  color: #cc2126;
  font-size: 13px;
  line-height: 13px;
  margin-top: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label_update {
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #0062a3;
  padding: 1px 10px 2px 10px;
  min-width: 100px;
  background-color: transparent;
  color: #fff;
  font-size: 13px;
  line-height: 13px;
  margin-top: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label_hit {
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #d95300;
  padding: 1px 10px 2px 10px;
  min-width: 100px;
  background-color: transparent;
  color: #d95300;
  font-size: 13px;
  line-height: 13px;
  margin-top: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ya-row-main {
  @extend .columns;

  @include breakpoint(40.0625em) {
    @include cols(3/12 * 100% auto, 0.9375rem in);
    align-items: flex-start;
  }
}

/* new adaptive style*/
.wrapper > .container {
  @media #{$large-up} {
    max-width: 1250px;
  }

  .row {
    @media #{$large-up} {
      max-width: 1230px;
    }
  }

  .ya-row-main > div:nth-child(1) {
    @media #{$large-up} {
      max-width: 248px;
      flex: 0 0 248px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .ya-row-main > div:nth-child(2) {
    @media #{$large-up} {
      padding-left: 0px; // !!!!!
    }
  }

  .home-slider-wrapper {
    @media #{$large-up} {
      //max-width: 956px;
      max-width: 952px;
    }
  }
}

// .row {
//     max-width: 100%;
// }
// .wrapper > .container {
//     //max-width: 100%;
//     max-width: 1896px;
// }
// .header > .container {
//     //max-width: 100%; 1896px
//     max-width: 1922px;
// }
// .header .menu-colums {
//     padding: 0;
// }
// .header__order {
//     @media screen and (min-width: 1368px) {
//         width: 312px;
//     }
// }
// .home-slider-wrapper {
//     @media screen and (min-width: 1440px) {

//     }
// }

.add-content {
  height: 100%;
  border-left: 1px solid #ddd3ec;
  border-right: 1px solid #ddd3ec;
  margin-top: -16px;
  padding-left: 30px;
  padding-right: 10px;
  padding-top: 20px;

  h1 {
    font-size: 26px
  }

  h2 {
    font-size: 22px
  }

  h3 {
    font-size: 20px
  }

  h4 {
    font-size: 18px
  }

  ul {
    list-style-position: inside;
    list-style-type: disc;
  }
}

.similars__row {
  //border-right: 1px solid #ddd3ec;

}

// картинки товаров

.product__item {
  padding-top: 5px;

  .product__img {
    max-width: 150px;
    display: block;
    margin: 2px auto;
  }

  .product__title {
    @media screen and (max-width: 478px) {
      font-size: 13px;
    }
    @media screen and (max-width: 407px) {
      font-size: 12px;
    }
    @media screen and (max-width: 377px) {
      font-size: 11px;
    }
    @media screen and (max-width: 350px) {
      font-size: 10px;
    }
  }
}

// адаптив шапки
.show-for-medium-up {
  @media screen and (max-width: 640px) {
    display: block !important;
  }
}

.header {
  @media screen and (max-width: 447px) {
    //margin-top: 45px;
  }
}

.sidebar_left, .new-menu {
  @media screen and (max-width: 640px) {
    display: none;
  }
}

.main-menu {
  @media screen and (max-width: 640px) {
    display: none;
  }
}

.menu {
  @media screen and (max-width: 375px) {
    margin-top: 0 !important;
  }
}

.header__greet, .header__order {
  @media screen and (max-width: 640px) {
    display: none;
  }
}

.header__search {
  @media screen and (max-width: 640px) {
    width: 97%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .f-product__right {
    height: 50px;
  }
}

.header__logo_l {
  @media screen and (max-width: 640px) {
    margin: 0 auto;
  }
}

//sidebar

// .catalog_widget .category_tree {
//     @media screen and (max-width: 760px) {
//         font-size: 13px;
//     }
//     @media screen and (max-width: 725px) {
//         font-size: 12px;
//     }
//     @media screen and (max-width: 670px) {
//         font-size: 11px;
//     }
// }

//обертка основного контента
// .ya-row-main {
//     max-width: 1200px;
//     margin: 0 auto;
//     float: none
// }
// топ блок окна регистрации
.registration-top {
  .container {
    text-align: center;
  }
}

///about/order/  cтили для страниц из админки

.content-inner__body.flatpage > div > div {
  @media screen and (max-width: 350px) {
    min-height: 335px !important;
    width: 100% !important;
  }
}

.managers {
  margin: 0;
  padding: 0;
  list-style: none;
}

.managers__item {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 15px;

  &:nth-child(even) {
    background-color: #F9F9F9;
  }

  @media screen and (max-width: 890px) {
    display: block;
    text-align: center;
  }
}

.managers__item > div {
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 270px;

  @media screen and (max-width: 960px) {
    font-size: .7rem;
  }
  @media screen and (max-width: 890px) {
    display: block;
    max-width: 100%;
    font-size: 1rem;
  }

}

.tabs-content {
  margin-bottom: 0;
}

// выгрузить каталог виджет

.sliza_widget_btn_1, .sliza_widget_btn_2 {
  bottom: 47% !important;
  @media screen and (max-width: 1200) {
    bottom: 20% !important;
  }
  @media screen and (max-width: 1024) {
    bottom: 0 !important;
    position: absoulte !important;
  }
  @media screen and (max-width: 768px) {
    // bottom: 22% !important;
    display: none !important;
  }
}

#sliza-wgt-button.sliza-btn-top-left, #sliza-wgt-button.sliza-btn-top-right {
  top: 58% !important;
}

#sliza-wgt-button[style].sliza-btn-top-left.sliza-btn-color-blue {
  @media screen and (max-width: 768px) {
    display: none !important;
    visibility: hidden;
  }
}

.a2a_kit.a2a_kit_size_32.a2a_floating_style.a2a_vertical_style {
  top: 45px !important;
}

.a2a_s_instagram {
  background-color: rgb(228, 64, 95) !important;
}

.vk-video-link {
  background: url(../img/logo/vk_video.png) no-repeat;
  width: 16px;
  height: 16px;
background-size: contain;
}

.youtube-video-link {
  background: url(../img/logo/youtube_video.png) no-repeat;
  width: 16px;
  height: 16px;
  background-size: contain;
}

.list-greed {
  .vk-video-link {
    display: inline-block;
    margin-left: auto;
    margin-right: 2px;
  }

  .youtube-video-link {
    display: inline-block;
    margin-left: 2px;
    margin-right: auto;
  }
}

.list-columns {
  .vk-video-link {
    display: inline-block;
    margin: 5px;
  }
  .youtube-video-link {
    display: inline-block;
    margin: 5px;
  }
}