.tooltip {
  position: relative;
}

.tooltip.wide::after {
  width: 140px;
  text-align: center;
}

.tooltip::after {
  // width: 50px;
  font-size: 11px;
  background: rgba(78, 44, 130, 0.55);
  border-radius: 8px 8px 8px 0;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  color: #FFF;
  content: attr(data-tooltip); /* Главная часть кода, определяющая содержимое всплывающей подсказки */
  top: -31px;
  left: 24px;
  opacity: 0; /* Наш элемент прозрачен... */
  padding: 3px 7px;
  position: absolute;

  visibility: hidden; /* ...и скрыт. */
  transition: all 0.4s ease-in-out; /* Добавить плавности по вкусу */
}

.tooltip:hover::after {
  opacity: 1; /* Показываем его */
  visibility: visible;
}

.payment_tooltip {
  position: relative;
  display: inline-block;

  .tooltipedtext {
    * {
      text-align: left;
    }

    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 1s;

    width: 130px;
    //height: 84px;
    text-align: left;
    padding: 5px 5px;
    border-radius: 0;
    border: 1px solid #DDDDDD;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;

    top: -30px;
    left: 166%;
    //box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);

    //&:after {
    //  content: "";
    //  position: absolute;
    //  top: 50%;
    //  right: 100%;
    //  margin-top: -8px;
    //  border-width: 8px;
    //  border-style: solid;
    //  border-color: transparent #DDDDDD transparent transparent;
    //}
  }

  &:hover {
    .tooltipedtext {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0s, opacity 1s;
    }
  }
}