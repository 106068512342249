// .flatpage tr {
// 	@media screen and (max-width: 450px) {
// 		display: flex;
// 		flex-direction: column;

// 		td {
// 			text-align: left !important;
// 			width: 100%;
// 			padding-left: 15px !important;
// 		}
// 	}
// } in case of vertical tables on flatpages


.white-popup-block .message {
  @media screen and (max-width: 900px) {
    height: auto;
    line-height: 1.6;
    font-size: 13px !important;
    padding-right: 20px;
    padding-left: 20px;
  }
}

.mfp-close {
  @media screen and (max-width: 900px) {
    top: -8px !important;
    right: -15px !important;
  }
}

#cart-checkout .mfp-close {
  @media screen and (max-width: 900px) {
    top: 30px !important;
  }
}

#cart-checkout .row__delivery,
#cart-checkout .row__payment {
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;

    .columns {
      width: 100% !important;
    }
  }
}


.single-product__video, .f-product__video {
  @media screen and (max-width: 900px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.list-greed {
  .single-product__video, .f-product__video {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.mfp-container {
  @media screen and (max-width: 900px) {
    overflow: scroll;
  }
}

.mfp-wrap {
  @media screen and (max-width: 900px) {
    position: fixed !important;
    top: 0 !important;
  }
}

.ya-row-main {
  @media screen and (min-width: 640px) and (max-width: 900px) {
    .small-12.columns.wrapper-content {
      padding-right: 3px;
      overflow: hidden;
    }
  }
}

.user_registration_form.ng-pristine ul.form .row {
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;

    .small-3.columns,
    .small-9.columns {
      width: 100%;
    }
  }
}

.contact-flat-page {
  @media screen and (max-width: 900px) {
    table:not(.table-contacts-row) tbody tr {
      display: flex;
      flex-direction: column;

      td {
        text-align: left !important;
        width: 100%;
      }
    }

    .table-contacts-contact {

      height: auto !important;
      padding-right: 10px;

      table tbody tr {
        display: table-row;

        td {
          width: initial !important;
        }
      }

      a {
        font-size: 14px !important;
      }
    }

    .table-contacts-row {
      br {
        display: none;
      }

      td:nth-child(1) {
        text-align: left !important;
        width: auto;
      }

      td:nth-child(2) {
        padding-left: 5px !important;
        padding-bottom: 5px !important;
        vertical-align: middle !important;

        a {
          font-size: 14px;
        }
      }
    }
  }
}

.content-inner__body.flatpage .contact-flat-page > div.table-contacts-button {
  @media screen and (max-width: 900px) {
    min-height: initial !important;
  }
}

.where-walk {
  @media screen and (max-width: 900px) {
    position: relative !important;
    top: initial !important;
    left: initial !important;
  }
}

.flatpage {
  @media screen and (max-width: 900px) {
    > div > div {
      max-width: 100%;
      overflow: hidden;
    }
  }
}

.p.dline {
  @media screen and (max-width: 900px) {
    p.dline {
      line-height: 1.6em !important;
    }
  }
}

.mobile-scroll-delivery {
  @media screen and (max-width: 900px) {
    overflow: scroll;
  }
}

.order-page-singularity {

  .order-desctop-hidden {
    display: none;
  }

  @media screen and (max-width: 900px) {
    th, td {
      word-break: break-all;
      min-width: 50px;
      width: auto;
      font-size: 11px;
    }
  }


  @media screen and (max-width: 530px) {
    .order-mobile-hidden {
      display: none;
    }

    .order-desctop-hidden {
      display: block;
    }
  }
}


.order-page-singularity th:nth-child(7),
.order-page-singularity td:nth-child(7) {
  @media screen and (max-width: 530px) {
    max-width: 74px;
    word-break: initial;
  }
}

.order-page-singularity td:nth-child(8) {
  @media screen and (min-width: 530px) and (max-width: 900px) {
    word-break: initial;
  }
}

.order-page-singularity table {
  @media screen and (max-width: 530px) {
    margin-right: 0;
    width: 100%;
  }
}

.order-page-singularity th:nth-child(2),
.order-page-singularity td:nth-child(2) {
  @media screen and (max-width: 530px) {
    max-width: 50px;
  }
}

.top__cart span.badge {
  @media screen and (max-width: 900px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
  }
}


.top__cart span.badge {
  @media screen and (min-width: 530px) {
    display: none !important;
  }
}

.mfp-auto-cursor .mfp-content,
.white-popup-block {
  @media screen and (max-width: 900px) {
    max-width: 99%;
    overflow: hidden;
  }
}


.contacts-padding {
  padding-left: 10px;
  padding-right: 10px;
}

.top__cart span.badge.cart-noborder {
  @media screen and (max-width: 900px) {
    border-color: transparent;
  }
}

.custom-table-column {
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
}

.custom-scrollable-table {
  @media screen and (max-width: 900px) {
    overflow: scroll;
  }
}

.custom-arrow-right {
  display: none;

  @media screen and (max-width: 900px) {
    display: block;
    text-align: right;
    margin-left: auto;
    color: #5c369b;
    font-size: 25px;
  }
}

.md-only {
  display: none;

  @media screen and (min-width: 530px) and (max-width: 900px) {
    display: block;
  }
}