.recents-block, .similars-block {
    padding: 0 36px;
    //padding-left: 0.9375rem;
    //padding-right: 1.6rem;
    .bx-wrapper {

        //padding-left: 0.5375rem;
        //padding-right: 0.2rem;
    }
    .bx-wrapper .bx-viewport {
        box-shadow: none;
        // @media  screen and (max-width: 1024px) {
        //     width: 74% !important;
        //     margin: 0 auto;
        // }
        @media  screen and (min-width: 1150px) {
            width: 840px !important;
            margin: 0 auto;
        }
        @media  screen and (max-width: 1020px) {
            width: 518px !important;
            margin: 0 auto;
        }
        @media  screen and (max-width: 736px) {
            width: 335px !important;
            margin: 0 auto;
        }
        @media  screen and (max-width: 425px) {
            //width: 335px !important;
            width: 165px !important;
            margin: 0 auto;
        }
        @media  screen and (max-width: 320px) {
            width: 165px !important;
            margin: 0 auto;
        }
    }
    .bx-wrapper .bx-controls-direction a {
        top: 25%;
    }
}

.similar__title, .recent__title {
    font-family: "HelveticaNeue";
    color: #000;
    //color: #5c369b;
    font-weight: 800;
    font-size: .9rem;
    margin-bottom: 5px;
}
.similar__title + .bx-wrapper, .recent__title + .bx-wrapper{
    margin-bottom: 10px !important;
    max-width: 840px !important;
    float:left;
    .bx-controls-direction a {
    opacity: 0;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
    }
}
.similar__title + .bx-wrapper:hover .bx-controls-direction a,  .recent__title + .bx-wrapper:hover .bx-controls-direction a, {
    opacity: 1;
}
.similar__list, .recent__list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}
.similar-product, .recent-product {
    a, a:active {
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
    display: block;
    //width: 180px !important;
    width: 165px !important;
    min-height: 180px;
    padding: 5px 0;
    text-align: center;
    font-family: "HelveticaNeue";
    color: #5c369b;
    .similar-product__img, .recent-product__img {
        display: inline-block;
        img {
            width: 140px;
            height: 140px;
        }
    }
    .similar-product__art, .recent-product__art {
        font-size: .65rem;
    }
    .similar-product__title, .recent-product__title {
        //height: 41px;
        height: .65rem;
        overflow: hidden;
        margin-bottom: 10px;
        line-height: 1;
        font-size: .65rem;
    }
    .similar-product__price, .recent-product__price {
        font-size: .85rem;
        line-height: 1;
        font-weight: bold;
        color: #394042;
        span {
            font-size: .8rem;
        }
    }
    
    .single-product__btns {
        margin: 0;
        .label_l {
            font-size: 8px;
        }
        .add-to-cart {
            margin-left: -5px;
            input {
                width: 38px;
                height: 26px;
            }
            .add-to-cart__btn {
                display: inline-block;
                vertical-align: top;
                padding: 2px 6px;
                height: 26px;
                margin-left: -4px;
                font-size: 8px;
            }
        }

    }
}

