#sub-modal {
    width: 230px;
    padding: 0;
    border-radius: 0;
    background: transparent;
    position: fixed;
    top:50px;
    right: -230px;
    z-index: 1;
}
#sub-modal {
    .sub-modal__close {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 2px;
        right: 5px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.8);
        font-size: 16px;
        &:hover {
            color: rgba(255, 255, 255, 1);
        }
    }
    .sub-modal__wrapper {
        width: 230px;
        background-color: #F5F5F5;
        overflow: hidden;
        border-radius: 3px;
    }
    .sub-modal__title {
        background-color: #48ae60;
        color: #fff;
        font-size: 15px;
        font-family: "Helvetica";
        font-weight: 700;
        padding: 5px 15px;
        text-align: left;
    }
    .sub-modal__body {
        padding: 5px;
    }
    .sub-modal__list {
        list-style: none;
        margin-bottom: 5px;
        display: block;
        padding: 5px 10px;
        border-radius: 3px;
        position: relative;
    }
    .sub-modal__item {
        display: flex;
        justify-content: flex-start;
    }
    .sub-modal__item-title {
        width: 55%;
        font-size: 12px;
        margin-right: 10px;
        color: #838080;
        span {
            font-size: 9px;
            margin-left: 3px;
        }
        .sub-modal__item-sale {
            font-size: 13px;
            color: $primary-color;
            margin-left: 5px;
        }
    }
    .sub-modal__item-qty {
        width: 45%;
        font-size: 13px;
        color: #222;
        font-weight: 700;
        text-align: right
    }
    .sub-modal__item-qty-sale {
        color: $primary-color;
    }
    .sub-modal__subtitle {
        color: #ff0000;
        font-size: 11px;
        text-align: center;
        margin-bottom: 10px;
    }
}