@include breakpoint(375px down) {
  .home-slider-wrapper {
    display: none;
  }
  .menu {
    margin-top: 50px;
  }
}

.heading {
  @include breakpoint(530px down) {
    .heading__container {
      flex-direction: row;
    }
    .heading__block-wrap {
      margin: 0;
    }

    .heading__block-wrap:first-child {
      margin-top: 0;
    }

  }
}

.flatpage > div {
  width: 100% !important;

}

.flatpage > div > table {
  width: 100% !important;
}

.flatpage > div > div {
  @media screen and (max-width: 350px) {
    min-height: 335px !important;;
    width: 225px !important;;
  }
}

.flatpage > div > div > div {
  @media screen and (max-width: 768px) {
    position: static !important;
    width: 100% !important;

  }

}

// блок социалок для шаринга
.a2a_kit {
  z-index: 999 !important;
  @media screen and (max-width: 768px) {
    display: none;
  }
  @media only screen and (hover: none) and (pointer: coarse) {
    display: none !important;
    visibility: hidden;
  }
}

// страны производители
.country-list {

  font-size: 12px;
  @media screen and (max-width: 768px) {
    font-size: 11px;
  }

}

//каталог

body.cart-block {

  .row .row {
    margin: 0;
  }
}


// bxslider
.bx-wrapper .bx-viewport {
  border: none !important;
}

.bx-wrapper .bx-controls-direction a {
  z-index: 999 !important;
}

//основное меню и контентная часть
.menu {
  @media screen and (max-width: 640px) {
    display: none;
  }
}

.tabs-content {
  @media screen and (max-width: 640px) {
    margin-top: 10px;
  }
}

.header__contacts {
  @media screen and (min-width: 1024px) {
    padding-top: 20px;
  }
}

.header__greet {
  @media screen and (min-width: 1024px) {
    padding-top: 10px;
  }
}

//баннеры
.main-banners {
  //.b-membership:first-child {
  //  display: none;
  //}

  .large-6 {
    margin-left: -13px;
    width: 990px;
    height: 100px;
    overflow: hidden;
  }

  @media screen and (max-width: 1024px) {
    .large-6 {
      margin-left: -15px;
      width: 749px;
      max-height: 100px;
      height: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    .large-6 {
      margin-left: 0;
      padding: 0;
      width: 100%;
      max-height: 100px;
      height: 100%;
    }
  }

  @media screen and (max-width: 640px) {
    .large-6 {
      width: 50%;
    }
    .columns {
      padding: 0 1px;
    }
  }
  @media screen and (max-width: 500px) {
    .large-6 {
      width: 100%;
    }
  }
}