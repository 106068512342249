@font-face {
  font-family: 'HelveticaNeue';
  font-display: fallback;
  src: url('../fonts/HelveticaNeue.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeue.woff') format('woff'),
  url('../fonts/HelveticaNeue.ttf') format('truetype'),
  url('../fonts/HelveticaNeue.svg#HelveticaNeue') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'HelveticaNeue-Medium';
  src: url('../fonts/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeue-Medium.woff') format('woff'),
  url('../fonts/HelveticaNeue-Medium.ttf') format('truetype'),
  url('../fonts/HelveticaNeue-Medium.svg#HelveticaNeue-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'HelveticaNeue-Light';
  font-display: fallback;
  src: url('../fonts/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeue-Light.woff') format('woff'),
  url('../fonts/HelveticaNeue-Light.ttf') format('truetype'),
  url('../fonts/HelveticaNeue-Light.svg#HelveticaNeue-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'HelveticaNeue-Thin';
  src: url('../fonts/HelveticaNeue-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeue-Thin.woff') format('woff'),
  url('../fonts/HelveticaNeue-Thin.ttf') format('truetype'),
  url('../fonts/HelveticaNeue-Thin.svg#HelveticaNeue-Thin') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rouble';
  src: url('../fonts/alsrubl-arial-regular.eot');
  src: url('../fonts/alsrubl-arial-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/alsrubl-arial-regular.woff') format('woff'), url('../fonts/alsrubl-arial-regular.ttf') format('truetype'), url('../fonts/alsrubl-arial-regular.svg#ALSRublArialRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Glyphicons Halflings';
  font-display: block;
  src: url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'),
  url('../fonts/glyphicons-halflings-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.rub {
  font-family: 'Rouble', Arial, sans-serif;
  line-height: normal;
  font-weight: normal;
}