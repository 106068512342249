.search-autocomplete-container {
  position: absolute;
  background-color: white;
  z-index: 999999;
  width: 100%;
  text-align: left;
  border: 1px solid #7c5cad;
  border-top: 0;
  font-size: 10px;
}

.search-autocomplete-list {
}

.search-autocomplete-list-item {
  line-height: 35px;
}

.search-autocomplete-list-item a {
  display: block;
  padding-left: 5px;
}

.search-autocomplete-list-item a:hover {
  background-color: #7c5cad;
  color: white;
  text-decoration: none;
}