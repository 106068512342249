.heading {
  display: block;
  position: fixed;
  padding: 5px 0;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  z-index: 1001;
  width: 100%;
}

.heading__container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: nowrap;
  max-width: 940px;
  margin: 0 auto;
  @media screen and (max-width: 446px) {
    align-items: center;
  }
}

.heading__block-wrap:first-child {
  margin-left: 30px;
}

.heading__block-wrap:last-child {
  //margin-right: 30px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 640px) {
    margin-right: 40px;
  }
}

.header__title {
  font-family: $font-regular;
  text-align: center;
}

.logo__row {
  @media #{$small-up} {
    padding-right: 0;
  }
  // @media #{$medium-up} {
  //   margin-top: 0;
  // }
}

.logo-tel__row {
  display: flex;
  justify-content: center;
  align-items: center;
  @media #{$small-up} {
    padding-right: 0;
  }
  @media screen and (max-width: 1024px) {
    display: block;
  }

  .header__contacts {
    flex: 2;
  }

  .header__search {
    //flex: 1;
    //justify-content: center;
    //flex-direction: column;
    //text-align: center;
    @media screen and (max-width: 1024px) {
      display: none;
    }

    .price-list {
      color: #394042;
      font-size: .8rem;
      display: block;
      text-decoration: none;
      position: absolute;
    }
  }

  // @media #{$medium-up} {
  //   margin-top: 0;
  // }
}

.header__logo_l {
  margin-top: 13px;
  @media #{$small-up} {
    max-width: 145px;
  }
  @media #{$large-up} {
    min-width: 210px;
  }

  img {
    width: 100%;
  }
}

.header__slogan {
  color: rgba(208, 20, 23, 1);
  font-size: 0.89em;
  text-align: center;
  text-transform: uppercase;
  transform: scaleY(1.2)
}

.header__contacts {
  @media #{$small-up} {
    margin-top: 13px;
  }
  @media #{$medium-up} {
    margin-top: 0;
  }
}

.contacts__tel-heading {
  display: block;
  text-align: center;
  @media #{$small-up} {
    margin-top: 0;
    font-size: .6rem;
  }
  @media #{$medium-up} {
    margin-top: -6px;
    font-size: .8rem;
  }
}

.contacts__tel {
  display: block;
  position: relative;
  text-align: center;
  font-family: $font-light;
  @media #{$small-up} {
    font-size: 1rem;
  }
  @media #{$medium-up} {
    font-size: 1.4rem;
  }

  &:before {
    position: absolute;
    content: '';
    width: 22px;
    height: 17px;
    display: inline-block;
    text-align: right;
    margin-left: -20px;
    @media #{$small-up} {
      top: 4px;
    }
    @media #{$medium-up} {
      top: 9px;
    }
    background-repeat: no-repeat;
    background-image: url(../img/icons/phone.svg);
  }
;
}

.no-svg .contacts__tel {
  :before {
    background-image: url(../img/icons/sprite1.png);
    background-position: -129px 0;
  }
}

.search {
  display: flex;
  margin: 0 auto;
  text-align: center;
  padding: 10px 0;
  @media screen and (max-width: 1024px) {
    display: block;
  }
}

.search__elem {
  display: inline-block;
  vertical-align: top;
  position: relative;
  @media screen and (max-width: 768px) {
    margin-left: 3px;
  }
  @media screen and (max-width: 740px) {
    margin-left: 18px;
  }
  @media screen and (max-width: 667px) {
    margin-left: 54px;
  }
  @media screen and (max-width: 650px) {
    margin-left: 0;
  }
}

.search__elem .glyphicon {
  position: absolute;
  color: rgba(0, 0, 0, 0.51);
  top: 50%;
  @media #{$small-up} {
    font-size: 12px;
    transform: translateY(-6px);
    left: 5px;
  }
  @media #{$medium-up} {
    font-size: 14px;
    transform: translateY(-7px);
    left: 9px;
  }
}

.search__clear {
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  cursor: pointer;
  top: 11px;
  right: 3px;
  opacity: 0;
  background: url(../img/icons/error.svg) center center no-repeat;
  background-size: contain;
}

input.search__input {
  border-radius: none;
  border: 1px solid #ddd3ec;
  text-align: left;
  background: #fff;
  text-transform: uppercase;
  @media #{$small-up} {
    width: 210px;
    font-size: 8px;
    padding: 2px 15px 2px 18px;
  }
  @media #{$medium-up} {
    width: 265px;
    height: 35px;
    font-size: 10px;
    padding: 2px 15px 2px 30px;
  }
  height: 31px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  margin: 0;

}

input.search__input:focus + .search__clear {
  opacity: 1;
  transition: opacity 0.8s;
}

.btn-search {
  display: inline-block;
  height: 35px;
  border: none;
  border-radius: 0;
  padding: .5rem 1rem .5rem 1.3rem;
  //border-radius: 0 3px 3px 0;
  text-transform: none;
  font-family: "Helvetica", Roboto, Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
  width: 90px;
  // line-height: 31px;
  text-align: center;
  margin-left: -5px !important;
  cursor: pointer;
  @media #{$small-up} {
    height: 31px;
  }
  @media #{$medium-up} {
    height: 35px;
  }
}

.header__greet {
  font-size: 10px;
  line-height: 13px;
  font-family: $font-light;
  text-align: right;
  margin-bottom: 10px;
  margin-top: 5px;
  @media #{$large-up} {
    font-size: 13px;
  }
}

.header__order {
  @include clearfix();
  @media #{$medium-up} {
    min-width: 160px;
    float: right;
  }

}

.cart {
  float: right;
}

#header_set_entity {
  //margin-right: 30px;
}

.cart-count, .order-count {
  font-size: 9px;
  font-family: $font-regular;
  float: right;
  @media #{$large-up} {
    font-size: 13px;
  }
}

.btn-order {
  float: right;
  font-size: 12px;
  font-family: $font-regular;
  display: inline-block;
  margin-top: 5px;
  padding: 7px 15px;
  margin-left: 10px;
  margin-right: 0;
  color: $white !important;
  border: none;
  border-radius: 0;
}

.btn-order:hover, .btn-order:focus, .btn-order:active {
  color: $white !important;
  text-decoration: none;
}

.cart-quantity, .order-total {
  color: #E35F56;
}


.cart.cart__header {
  color: $primary-color;
  //border-bottom: 1px solid #E35F56;
  margin-left: 8px;

  a {
    color: $primary-color;
    display: inline-block;
    @include clearfix;
  }

  a:hover {
    //border-bottom: 1px solid $primary-color;
  }

  .order-total {
    color: $primary-color;
  }

  .cart-quantity {
    color: $primary-color;
  }

  .cart__header-link:hover {
    .order-total, .cart-quantity, .order-count {
      text-decoration: underline;
    }
  }
}

.heading__block-wrap {
  .header__order {
    min-width: 0;
    position: relative;
    top: 2px;
  }

  @media screen and (max-width: 425px) {
    .enter-links__item_login, .enter-links__item_reg, .top__profile {
      span {
        display: none;
      }
    }
    .enter-links__item.enter-links__item_login:before, .enter-links__item.enter-links__item_reg:before {
      top: -10px;
    }
    .top__profile {
      margin-right: 4px;
    }
  }
}

.main-menu {
  margin-bottom: 15px;

  &__list {
    @include inline-list;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    display: flex;
    justify-content: space-between;
  }

  &__item {
    margin-left: 2px !important;
    text-align: center;
    flex: 1;
    //width: 16.6666666%;
    &:first-child {
      margin-left: 0 !important;
    }
  }

  &__link {
    padding: 10px 0;
    //border: 1px solid scale-color($primary-color, $lightness: -15%);
    border: none;
    border-radius: 0;
    background-color: $primary-color;
    text-transform: uppercase;
    font-size: 13px;
    color: $white !important;
    transition: background-color 300ms ease-out;

    &:hover, &:focus, &:visited, &:active {
      background-color: scale-color($primary-color, $lightness: 7.5%);
      color: $white !important;
      text-decoration: none;
    }

    @media screen and (max-width: 1024px) {
      font-size: 11px;
    }

    @media screen and (max-width: 880px) {
      font-size: 8px;
    }
    @media screen and (max-width: 667px) {
      font-size: 7px;
    }
  }
}

// hamburger menu
.humb {
  //display: inline-block;
  display: none;
  z-index: 9999;
  cursor: pointer;
  width: 33px;
  height: 12px;
  background: url('../img/icons/menu-h1.svg') center center no-repeat;
  background-size: cover;
  // top: 3px;
  // position: relative;


  @media screen and (max-width: 450px) {
    margin-left: 15px;
  }
  @media screen and (max-width: 640px) {
    display: block;

  }

  .glyphicon.glyphicon-menu-hamburger {
    font-size: .9rem;
    color: #5c369b;

    &:hover {
      color: scale-color($primary-color, $lightness: 25%);
    }
  }

}

.h-menu {
  display: block;
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -250px;
  background: #f7f7f7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow-y: auto;

}

.h-menu__close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: scale-color($primary-color, $lightness: 25%);
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: $primary-color;
  }
}

.h-menu__main {
  padding-top: 30px;
}

.h-menu__list {
  display: block;
  margin: 0;
  padding: 10px 23px;
  list-style: none;
}

.h-menu__item {
  display: block;
  width: 100%;
}

.h-menu__link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  padding: 0 10px;
  height: 40px;

  &:hover, &:focus {
    background-color: rgba(229, 229, 229, 0.6);
    text-decoration: none;
  }
}

.h-menu__item-heading {
  margin-left: 10px;
}

.h-menu__list_small {
  .h-menu__link {
    font-size: .9rem;
    text-decoration: underline;

    &:hover, &:focus {
      background-color: #f7f7f7;
      text-decoration: none;
    }
  }
}