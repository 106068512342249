$pagination-padding: 0.0625rem 0.625rem 0.0625rem;

ul.pagination {

  li {
      font-size: 12pt;
      height: auto;
      //border-radius: 3px;
      border-radius: 0;

      &.current {
        font-weight: 700;
        color: white;
        background: $primary-color;
        padding: $pagination-padding;
      }

      a,
      button {
    	   color: #5c369b;
         text-decoration: none;
      }
  }
}

.pagination-wrap {
  text-align: center;
  background-color: $white;
}
.pagination-wrap.center-block {
  margin-top: 20px;
}

.pagination-previous {

  &.disabled {
    padding: $pagination-padding;
  } 
}

.pagination-next {

  &.disabled {
    padding: $pagination-padding;
  }
}
