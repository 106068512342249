.home-slider-wrapper {
  max-width: 970px;
  padding: 0;
  margin: 0 auto;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  box-sizing: content-box;

  ul {
    list-style: none !important;

    &.bxslider {
      li {
        width: 970px;
      }
    }
  }

  .bx-wrapper .bx-next {
    right: 20px;
  }
}