.sidebar_left {
    //border-radius: 3px 3px 0 0;
    //border: 1px solid $border-color;
    //margin-right: -15px;
    position: relative;
    border-radius: 0;
    border: 0;
    width: 100%;
    &:after {
        position: absolute;
        content: '';
        height: 1px;
        width: 100%;
        background-color: #ccc;
        bottom: 0;
        //right: -6px;
    }
    .sidebar__title {
        background-color: $white;
        color: $primary-color;
        padding: 10px 10px 9px 15px;
        //border-bottom: 1px solid $border-color;
        font-family: $font-regular;
        font-size: 13px;
        margin-bottom: 17px;
    }
    .catalog {
        margin-top: 0;
    }
}

.catalog {
    margin-top: 15px;
    &__title {
        text-align: center;
        margin-bottom: 15px;
    }
;
    &__list {
        list-style: none;
    }
    &__link {
        color: inherit;
        font-family: "HelveticaNeue";
        border-bottom: 1px solid $pp-grey;
        background-color: #fff;
        display: block;
        padding: 0.66667rem;
        transition: background 300ms ease;
        &:hover {
            background-color: $pp-snow;
        }
    }
    &__link-second {
        color: inherit;
        font-family: "HelveticaNeue";
        border-bottom: 1px solid $pp-grey;
        background-color: $pp-snow;
        display: block;
        padding: 0.66667rem;
        transition: background 300ms ease;
        &:hover {
            background-color: $gainsboro;
        }
    }
    &__link-third {
        color: inherit;
        font-family: "HelveticaNeue";
        border-bottom: 1px solid $pp-grey;
        background-color: $gainsboro;
        display: block;
        padding: 0.66667rem;
        transition: background 300ms ease;
        &:hover {
            background-color: $base;
        }
    }
    &__link-active {
        background-color: #999999;
        &:hover {
            background-color: $base;
        }
    }
}

.catalog_mobile {
    .catalog__link {
        background-image: url(../img/icons/menu-arrow.png);
        background-repeat: no-repeat;
        background-position: 98%;
    }
}

.catalog__qty {
    float: right;
    display: inline-block;
    border: 1px solid $pp-grey;
    background-color: #fff;
    font-size: 13px;
    padding: 2px;
    min-width: 28px;
    text-align: center;
}

.catalog__text {
    display: inline-block;
    width: 160px;
    vertical-align: middle;
    line-height: 1;
    font-size: 13px;
}

.content {
    //border-left: 1px solid scale-color($primary-color, $lightness: -15%);
    //border-right: 1px solid scale-color($primary-color, $lightness: -15%);
}

.accordion .accordion-navigation:first-child > a, .accordion dd:first-child > a {
    border: 1px solid scale-color($primary-color, $lightness: -15%);
    background-color: $primary-color;
    color: $white;
    border-radius: 6px 6px 0 0;
    margin-top: 0;
}

.accordion .accordion-navigation:last-child > a, .accordion dd:last-child > a {
    border: 1px solid scale-color($primary-color, $lightness: -15%);
    background-color: $primary-color;
    color: $white;
    border-radius: 0 0 6px 6px;
}

.accordion .accordion-navigation > a, .accordion dd > a {
    border: 1px solid scale-color($primary-color, $lightness: -15%);
    background-color: $primary-color;
    color: $white;
    margin-top: -1px;
}

.accordion .accordion-navigation:hover > a, .accordion dd:hover > a,
.accordion .accordion-navigation:active > a, .accordion dd:active > a,
.accordion .accordion-navigation:visited > a, .accordion dd:visited > a {
    border: 1px solid scale-color($primary-color, $lightness: -15%);
    background-color: scale-color($primary-color, $lightness: -15%);
    color: $white;
}


// перенесено из left_bar.css с хостинга

ul {

    .catalog_widget & {
        background: transparent url('../img/menu-stripe.png') 5px top repeat-y;
        padding: 0;
        display: none;
        line-height: 17px;
        font-size: 14px;
        list-style: none;
        margin-left: 0;

        &.root {
            display: block;
           // margin: 0 10px 10px 10px;
        }
    }

    .extendable.extended > & {
        display: block;
    }
}

a {
    .catalog_widget ul.root > li > &,
    .catalog_widget ul.root > li > &:visited {
        font-weight: normal;
    }

    .catalog_widget li.empty.active &,
    .top-active > & {
        color: red !important;
    }


    .sidebar.left .price_link & {
        display: block;
        background: transparent url('../img/excel.png') 4px 2px no-repeat;
        font-size: 18px;
        padding: 9px 18px 9px 40px;
        text-decoration: underline;
        width: 192px;

        &:hover {
            background-color: #FFF;
            outline: 1px solid #5c369b;
        }
    }
}
.category_tree.root.level_0 {
    //padding-top: 5px;
}
.catalog_widget ul.root a {
    display: inline;
    padding-top: 7px;
    position: relative;
    color: #6b6b6b;
}
.catalog_widget ul.root li.active > a {
    color: $primary-color;
}

li {

    .catalog_widget ul & {
        cursor: pointer;
        padding: 4px 0 4px 18px;
        background-color: transparent;
        background-position: 6px 14px;
        background-repeat: no-repeat;
        line-height: 17px;

        &.empty {
            position: relative;

            //background-image: url('../img/menu-empty.png');
            background-position: 6px 14px;

            &:last-child {
                //background-image: url('../img/menu-last-empty.png');
                background-position: 5px 14px;
            }
        }

        &.extendable {
            position: relative;

            &.extended {
                background-image: url('../img/menu-open.png');
                background-position: 0 8px;
                color: red;

                &:last-child {
                    background-image: url('../img/menu-last-open.png');
                    background-position: 0 8px;
                }
            }

            &.collapsed {
                background-image: url('../img/menu-closed.png');
                background-position: 0 8px;

                &:last-child {
                    background-image: url('../img/menu-last-closed.png');
                    background-position: 0 8px;
                }
            }
            &.collapsed.active {
                cursor: default;
                color: red;
            }

        }
        &.empty.active {
                cursor: default;
                color: red;
        }
    }
}

h2 {
    
    .catalog_widget & {
        margin: 0;
        padding: 0;
    }
}
.catalog_widget ul {
    li.extendable.collapsing_toggler.mark,  li.extendable.collapsing_toggler.extended.mark, .level_0.empty.mark {
        &:after {
            top: 14px;
        }
    }
    li.extendable.collapsing_toggler.mark,  li.extendable.collapsing_toggler.collapsed.mark, .level_0.empty.mark{
        // background-image: none;
        background-color: #EFEFEF ;
        
        a {
            color: #6D6D6D;
            //font-family: "Helvetica";
            font-weight: 700;
        }
        .children a {
            font-weight: 500;
        }
        ul li.empty:last-child {
            background-image: none !important;
        }
    }

    .level_0.empty.mark {
        padding: 15px 20px;
        transition: all 0.2s ease;
        border-bottom: 1px solid #CCC;
        &:hover {
            background: rgba(115, 77, 172, 0.23);
            a {
                text-decoration: none;;
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .level_1, .level_2 {
        font-size: 14px;
    }
    


    @media screen and (min-width: 640px){
        word-wrap: break-word;
        hyphens: auto;
        font-size: 12px;

        .level_1, .level_2 {
        font-size: 11px;
        }
    }
    @media screen and (min-width: 1024px){
        word-wrap: normal;
        hyphens: initial;
        font-size: 16px;

        .level_1, .level_2 {
            font-size: 14px;
        }
    }
}

.new-menu  {
    .level_0.accordion {
        margin: 0px auto;
    }
}

.catalog__link.catalog__link-second {
        display: none;
        width: 100%;
    }

.catalog__link.catalog__link-third {
    display: none;
    width: 100%;
}
