aside {
    .site-news, .site-reviews {
        border: 0;
        background-color: #fff;
        @media screen and (max-width: 640px) {
            display: none;
        }    
    }
    .site-news__header,  .site-reviews__header {
        //background-color: rgba(92, 52, 153, 0.8);
        background-color: #fff;
        border-radius: 3px 3px 0 0;
        text-transform: uppercase;
        //color: #fff;
        color: rgba(92, 52, 153, 0.8);
        font-size: 13px;
        padding: 0px 5px 0 10px;
        color: rgba(92, 52, 153, 0.8);
        font-family: "HelveticaNeue";
        margin-bottom: 5px;
        @media screen and (max-width: 768px) {
             font-size: 14px;
        }
    }
    .site-reviews__list, .site-news__list {
        margin: 0;
        padding: 0px 5px 5px 0px;
        list-style: none;
    }
    .site-reviews__link, .site-news__link {
        text-decoration: none;
        &:active, &:hover {
            text-decoration: none;
        }
    }
    .site-reviews__item, .site-news__item {
        margin-bottom: 25px;
        padding: 0px 5px 0 10px;
        position: relative;
        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -15px;
            left: 0px;
            right: -5px;
            height: 1px;
            background-color: $border-color;
        }
        &:last-child {
            margin-bottom: 5px;
            &:after { 
                display: none;
            }
        }
    }
    .site-reviews__author, .site-news__title,  .site-reviews__date, .site-news__date{
        color: #000;
        font-size: 14px;
        font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        //font-weight: bold;
        line-height: 1;
        @media screen and (max-width: 768px) {
            font-size: 12px;
        }
    }
    .site-reviews__date, .site-news__date {
        margin-bottom: 0px;
    }
    .site-reviews__text, .site-news__text {
        color: #565555;
        font-size: 13px;
        line-height: 1.1;
        margin-bottom: 15px;
        @media screen and (max-width: 768px) {
            font-size: 10px;
            line-height: 1;
        }
    }
    .site-reviews__footer, .site-news__footer {
        text-align: right;
        margin-top: -15px;
    }
    .site-reviews__btn, .site-news__btn {
        padding: .3rem .5rem .3rem .5rem;
        text-decoration: underline;
        display: inline-block;
        margin: 0;
        font-size: 12px;
        &:hover {
            text-decoration: none;
        }
        @media screen and (max-width: 768px) {
            font-size: 10px;
        }
    }
}
