.menu {
  &__container {
    display: flex;
    height: 54px;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: nowrap;
    border: 1px solid $border-color;

  }

  &__item {
    display: flex;
    //flex: 0 1 194px;
    justify-content: center;
    align-items: center;
    width: 20%;
    padding: 10px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border: 0;
    //border-right: 1px solid $border-color;
    //border-bottom: 1px solid $border-color;
    background-color: rgba(255, 255, 255, .7);
    transition: .25s all ease-out;
    @media screen and (max-width: 1024px) {
      font-size: 13px;
    }

    @media screen and (max-width: 800px) {
      font-size: 12px;

    }
    @media screen and (max-width: 768px) {
      .menu__item-heading {
        font-size: 11px;
      }
    }
    @media screen and (max-width: 740px) {
      font-size: 11px;
      .menu__item-heading {
        display: none;
      }
    }
    img {
      @media screen and (max-width: 1024px) {
        max-width: 21px;
      }
      @media screen and (max-width: 740px) {
        max-width: 30px;
      }
    }
  }

  &__item:last-child {
    border-right: none;
  }

  &__item:hover {
    background-color: rgba(229, 229, 229, .6);
  }

  &__item.active {
    background-color: rgba(229, 229, 229, .6);
  }

  &__item.active:hover {
    background-color: rgba(229, 229, 229, .6);
    cursor: default;
    text-decoration: none;
  }
  &__item-heading {
    margin-left: 5px;
  }
  @media #{$small-only} {
    &__container {
      flex-wrap: wrap;
    }

    &__item {
      width: 100%;
      padding: 4px 10px;
      text-align: center;
      border-right: none;
      border-bottom: 1px solid $border-color;
    }

    &__item:last-child {
      border-bottom: none;
    }
  }

  @media #{$medium-only} {
    &__item {
      padding: 10px 1px;
      font-size: 12px;
    }
  }
}

.content-block {
  background-color: #fff;
  border: 1px solid $border-color;
  border-top: none;

  &__container {
    display: flex;
    justify-content: space-around;
    align-content: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  &__item {
    width: 20%;
    padding: 5px;
    text-align: center;
  }

  &__img {
    width: 100%;
  }

  &__title {
    font-size: 16px;
    line-height: 18px;
  }

  @media #{$small-only} {
    &__item {
      width: 50%;
    }
  }

}

.orbit-container {
  margin-bottom: 20px;
}

.content__title {
  //border-bottom: 1px solid scale-color($primary-color, $lightness: -15%);
  padding-left: 1rem;
  padding-bottom: 1rem;
  font-family: $font-light;
  font-size: 20px;
  color: scale-color($primary-color, $lightness: -15%);
}

.content__title_action {
  position: relative;
}

.tabs {
  margin-top: 15px;
}

.tabs__item {
  width: 20%;
}

.tabs dd > a, .tabs .tab-title > a {
  padding: 0 15px;
  height: 80px;
  padding-bottom: 10px;
  background-color: rgba(255, 255, 255, .7);
  border: 1px solid $border-color;
  font-size: 13px;
  text-align: center;
  color: scale-color($primary-color, $lightness: -15%);
  outline: none;
  transition: all 300ms ease-out;
  &:hover {
    background-color: scale-color(rgba(255, 255, 255, .7), $lightness: -7.5%);
    transition: all 300ms ease-out;
  }
}

.tabs dd.active a, .tabs .tab-title.active a {
  border: 1px solid $border-color;
  color: scale-color($primary-color, $lightness: -15%);
  border-bottom: 1px solid scale-color($primary-color, $lightness: -15%);
  background-color: rgba(255, 255, 255, .7);
  outline: none;
}

.tabs-content > .content {
  //border-radius: 0 0 3px 3px;
  border: 1px solid $border-color;
  border-top: 0;
  //margin-top: -2px;
  padding-bottom: 0;
}

.product {
  &__list {
    margin: 0;
  }
  &__item {
    text-align: center;
    margin-bottom: 30px;
    position: relative;

  }
  &__item:hover &__submenu {
    height: 120px;
  }

  &__info {
    position: absolute;
    bottom: -24px;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 7px 19px 0;
    //&__title {
    //  line-height: 1;
    //}
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: #fff;
      opacity: .7;
      z-index: -1;
    }

    //&__blur-bg {
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  right: 0;
    //  width: 150px;
    //  height: 100%;
    //  margin: 2px auto;
    //  background-position: 0 100%;
    //  filter: blur(2px);
    //  z-index: -2;
    //}
  }

  &__submenu {
    @media screen and (max-width: 1024px ) {
      display: none;
    }
    position: relative;
    text-align: left;
    font-size: 12px;
    overflow: hidden;
    margin: 0 -19px;
    padding: 14px 19px 0;
    height: 0;
    transition: height .3s ease;
    &__list {

    }
    &__item {
      &:not(:first-child):before {
        content: "\2666";
        width: 10px;
        display: inline-block;
        text-align: center;
        color: #5c369b;
        font-size: 0.6rem;
      }
    }
  }
  &__submenu-more {
    display: none;
    color: darkgray;
    &:hover {
      color: darkgray;
      text-decoration: none;
    }
    &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      position: relative;
      background: url(../img/arrow_right_gray.png) center center no-repeat;
      background-size: cover;
      width: 8px;
      height: 8px;
      left: 0;
      top: -1px;
    }
  }

  //&__title {
  //  display: inline-block;
  //  font-size: 16px;
  //  line-height: 18px;
  //  font-family: $font-regular;
  //  padding: 10px 10px;
  //}
  //&__item:hover &__title {
  //  text-decoration: underline;
  //}
  &__img {
    margin-bottom: 10px;
  }
}

.product__item .product__title {
  @media screen and (min-width: 640px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 16px;
  }
}

// Тематические подборки

//.subject__list {
//  list-style: none;
//
//}
//
//.subject__item {
//
//  @include clearfix;
//  padding: 5px;
//  padding-bottom: 15px;
//  //border-bottom: 1px solid scale-color($primary-color, $lightness: -15%);
//  font-family: $font-light;
//  color: scale-color($primary-color, $lightness: -15%);
//}
//
//.subject__link {
//  display: inline-block;
//}
//
//.subject__img {
//  float: left;
//}
//
//.subject__description {
//  float: left;
//  margin-left: 20px;
//}
//
//.subject__text {
//  max-width: 370px;
//  font-size: 14px;
//  line-height: 16px;
//}
//
//.subject__title {
//  @media screen and (max-width: 478px) {
//    font-size: 13px;
//  }
//  @media screen and (max-width: 407px) {
//    font-size: 12px;
//  }
//  @media screen and (max-width: 377px) {
//    font-size: 11px;
//  }
//  @media screen and (max-width: 350px) {
//    font-size: 10px;
//  }
//}
//
//.subject__title:hover {
//  //font-size: 20px;
//  text-decoration: underline;
//}

//новый вид подборок
.view-checkbox {
  @media screen and (max-width: 424px) {
    display: none;
  }
  text-align: right;

  input[type=checkbox] {
    display: none;
  }
  label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 15px;
    color: rgba(78, 44, 130, 0.8);
    font-size: 11px;
    &:before {
      content: "";
      display: inline-block;
      width: 13px;
      height: 13px;
      margin-right: 10px;
      position: absolute;
      left: 0;
      bottom: 1px;
      background-color: #fff;
      border: 1px solid rgba(78, 44, 130, 0.8);
      border-radius: 3px;
    }
  }
  input[type=checkbox]:checked + label:before {
    content: "\2713";
    font-size: 12px;
    color: rgba(78, 44, 130, 0.8);
    text-align: center;
    line-height: 12px;
  }
}

// Тематические подборки

.manufacturer {
  &__list {
    padding: 20px;
    border: 1px solid #e3e3e3;
    min-height: 170px;
  }
  &__link {
    display: inline-block;
    text-align: center;
  }
  &__link-text {
    font-size: 1rem;
    font-family: $font-light;
  }
}

// Таймер слайдера
.home-slider-wrapper {
  position: relative;
  @media screen and (max-width: 1024px) {
    max-width: 719px !important;
  }
  @media screen and (max-width: 768px) {
    max-width: 538px !important;
  }
  @media screen and (max-width: 736px) {
    max-width: 522px !important;
  }
  @media screen and (max-width: 732px) {
    max-width: 516px !important;
  }
  @media screen and (max-width: 667px) {
    max-width: 470px !important;
  }
}

.home-slider-wrapper-timer {
  position: absolute;
  bottom: 90px;
  right: 13px;
  z-index: 99;
  width: 349px;
  height: 44px;
  @media screen and (max-width: 768px) {
    bottom: 60px;
    right: -46px;
  }
  @media screen and (max-width: 425px) {
    bottom: 60px;
    right: -46px;
  }
}

#time1 {
  display: none;
}