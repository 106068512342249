.cart__title {
  // новые стили
  //max-width: 800px;
  //max-width: 940px;
  max-width: 1170px;
  margin: 0 auto;
  position: relative;

  background: $primary-color;
  padding: 5px 15px;
  margin-bottom: 16px;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid scale-color($primary-color, $lightness: -15%);
  color: $white;

  .btn_clear {
    font-size: 12px;
    display: inline-block;
    margin-left: 5px;
    color: $white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.cart__title-link {
  margin-left: 30px;
  color: #c4b9d6;
}

.cart-list {
  border-radius: 3px;

  table {
    border-radius: 3px;
    margin-bottom: 15px;
    border-collapse: collapse;
    min-width: 100%;

    thead {
      border-radius: 3px 3px 0 0;
    }

    tr {
      border-bottom: 1px solid scale-color($primary-color, $lightness: -15%);

      th {
        font-size: 11px;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
      }

      td {
        padding: 10px 10px;
        font-size: 11px;

        a {
          line-height: 14px;
          font-size: 13px;
          display: inline-block;
        }

        input {
          font-size: 11px;
          padding: 5px;
          height: 28px;
          width: 36px;
          display: inline-block;
          margin: 0;
          margin-right: 5px;
        }
      }
    }

    tr.even, tr.alt, tr:nth-of-type(even) {
      background: #fff;
    }
  }
}

.cart__img-wrap {

}

.cart-list__number {
  text-align: center;
}

.cart-list__art {
  display: inline-block;
  padding: 2px 0;
  font-size: 10px;
  line-height: 10px;
  color: #5c369b;
  margin-bottom: 2px;

  span {
    font-size: 12px;
  }
}

.cart-list__price {
  font-size: 13px;
  font-weight: bold;

  &.sale {
    font-family: $font-regular;
    font-size: 11px;
    line-height: 12px;
    color: $monsoon;
    text-decoration: line-through;
  }
}

.cart-list__sale {
  font-size: 13px;
  line-height: 14px;
  font-weight: bold;
  color: #ff4949;
}

.cart-list__remove,
.cart-list__add {
  display: inline-block;
  float: right;
  background-image: url(../img/icons/remove.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.cart-list__add {
  background-image: url(../img/icons/add.png);
}

.cart-list__btn_add {
  color: #fff;
  //width: 180px;


  float: left;
  margin-bottom: 15px;
}

.cart-list__btn_order {
  color: #fff;
  //width: 180px;
  float: right;
  margin-bottom: 15px;

  &:hover {
    text-decoration: underline;
  }
}

.button.small.cart-list__btn_order, .button.small.cart-list__btn_add {
  padding: .8rem;
}


.cart-widget {
  border-radius: 3px;
  //border: 1px solid scale-color($primary-color, $lightness: -15%);
  //margin-bottom: 25px;
  &__title {
    //background: $primary-color;
    //border-bottom: 1px solid scale-color($primary-color, $lightness: -15%);
    background-color: #48ae60;
    color: $white;
    font-size: 15px;
    font-family: "Helvetica";
    font-weight: 700;
    padding: 5px 15px;

  }

  &__body {
    padding: 5px;
    @media screen and (max-width: 965px) {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    p {
      font-size: 13px;
      line-height: 14px;
      margin-bottom: 10px;
    }
  }

  .cart__list {
    list-style: none;
    margin-bottom: 5px;
    display: block;
    padding: 5px 5px;
    border-radius: 3px;
    position: relative;
    @media screen and (max-width: 965px) {
      width: 25%;
      margin-left: 20px;
    }

  }

  .cart__item {
    display: block;
    font-family: "HelveticaNeue", "Helvetica", "Roboto", "Arial", "sans-serif";


    @include clearfix;

    &-title {
      float: left;
      font-size: 12px;
      margin-right: 10px;
      color: #838080;

      span {
        font-size: 9px;
        margin-left: 3px;
      }

      .cart__item-sale {
        color: $primary-color;
        font-size: 13px;
        margin-left: 5px;
      }
    }
  ;

    &-qty {
      float: right;
      font-size: 13px;
      color: #222;
      font-weight: 700;
    }
  ;

    &-qty_sale {
      color: $primary-color;
    }
  ;

  }
;

  .cart__list-main {
    .cart__item:nth-child(1) {
      .cart__item-title {
        float: left;
      }

      .cart__item-qty {
        float: none;
        text-align: right;
      }
    }

    .cart__item:nth-child(2), .cart__item:nth-child(3), .cart__item:nth-child(4) {
      display: flex;
      justify-content: flex-start;

      .cart__item-title {
        width: 55%;
      }

      .cart__item-qty {
        width: 45%;
        text-align: right;
      }
    }
  }

  .cart__list.cart__list_msg {
    .cart__item-qty {
      font-weight: 500;
    }
  }

  .cart__btn {
    width: 100%;
    margin: 0 auto;
  }

  .cart__list.cart__list_weight {
    .cart__item {
      line-height: .9;

      &-title {
        font-size: 11px;
        display: inline-block;
        float: none;
      }
    ;

      &-qty {
        font-size: 11px;
        display: inline-block;
        float: none;
      }
    ;
    }
  }

  .cart__list.cart__list_msg {
    line-height: 1;

    .cart__item {
      &-title {
        color: #ff0000;
        float: none;
        font-size: 11px;
        display: inline-block;
        line-height: 1.2;
      }
    ;

      &-qty {
        color: #ff0000;
        float: none;
        font-size: 10px;
        display: inline-block;
        margin-left: 5px;
      }
    ;
    }
  }
}

//адаптив новые стили
.cart-sidebar {
  width: 230px;
  position: absolute;
  top: 160px;
  left: 960px;
  z-index: 1;
  background-color: #F5F5F5;
  border: 2px solid #fff;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;

  @media screen and (max-width: 965px) {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
  @media screen and (max-width: 737px) {
    padding: 0;
    //background-color: transparent;

    .cart__item-title {
      color: $primary-color;
    }
    .cart__item-qty {
      color: $primary-color;
      font-family: "Helvetica";
      font-weight: 700;
    }

    .cart-widget__body {
      padding: 0 5px;
      justify-content: space-around;
    }

    .cart-widget__title, .cart__list_weight, .cart__list_msg {
      display: none;
    }

    .cart__list.cart__list-main {
      width: initial;
      text-align: center;
      margin-bottom: 0;

      .cart__item:nth-child(2), .cart__item:nth-child(3) {
        display: none
      }

      .cart__item:nth-child(1) {
        display: inline-block;

        .cart__item-title {
          display: inline-block;
          float: none;
        }

        .cart__item-qty {
          display: inline-block;
          float: none;

        }
      }

      .cart__item:nth-child(4) {
        display: inline-block;
        margin-left: 20px;

        .cart__item-title {
          display: inline-block;
          float: none;
          width: inherit;
        }

        .cart__item-qty {
          display: inline-block;
          float: none;
          width: inherit;
        }
      }
    }

    .buttons {
      margin: 0;
    }

    .cart-list__btn_add, .cart-list__btn_order {
      margin-bottom: 0;
      display: inline-block;
    }

  }

  .button {
    display: block;
    padding: .5rem !important;
  }
}

.cart-sidebar.fixed-top {
  position: fixed;
  top: 28px;
  right: 0;
  z-index: 99;

  @media screen and (max-width: 448px) {
    //top: 80px;
    top: 18px;
  }
}

.cart-data {
  margin-top: 0px;
}

.cart-list {
  // max-width: 700px;
  // margin-left: 20px;
}

.buttons {
  //max-width: 800px;
  @include clearfix;
  display: block;
  margin: 0 auto;
  @media screen and (max-width: 965px) {
    margin: 0;
    .button {
      float: none;
    }
  }
  @media screen and (max-width: 734px) {
    margin: 0 auto;
    .cart-list__btn_add {
      float: left;
    }
    .button.cart-list__btn_order {
      float: right;
    }
  }

}

.button.cart-list__btn_add {
  color: rgba(92, 52, 153, 0.8);
  text-decoration: underline;
  background: transparent;
  border: none;
  font-size: 0.7125rem;
}

.button.cart-list__btn_order {
  font-size: 0.65rem;
}

//cart cart/
.cart-list {
  @media screen and (min-width: 965px) {
    min-height: 290px;
  }

  /* Zebra striping */
  tr:nth-of-type(odd) {
    background: #eee;
  }

  th {
    background: rgba(92, 52, 153, 0.8);
    color: white;
    font-weight: bold;
  }

  td, th {
    padding: 6px;
    border: 1px solid #ccc;
    text-align: left;
  }

  @media only screen and (max-width: 640px), (min-device-width: 768px) and (max-device-width: 1024px) {

    .cart-list__number {
      text-align: left;
    }
    .cart-list__remove, .cart-list__add {
      float: left;
      margin-top: 4px;
    }

    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
    }

    table td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50% !important;
      min-height: 40px;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 15px;
      left: 15px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }

    /*
    Label the data
    */
    td:nth-of-type(1):before {
      content: "#";
    }
    td:nth-of-type(2):before {
      content: "Фото";
    }
    td:nth-of-type(3):before {
      content: "Категория";
    }
    td:nth-of-type(4):before {
      content: "Название товара";
    }
    td:nth-of-type(5):before {
      content: "Количество, Ед";
    }
    td:nth-of-type(6):before {
      content: "Цена";
    }
    td:nth-of-type(7):before {
      content: "Сумма";
    }
    td:nth-of-type(8):before {
      content: "Скидка";
    }
    td:nth-of-type(9):before {
      content: "Удалить";
    }
  }

}

.cart-list {
  .table__wrapper {
    //max-width: 940px;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 40px;
    position: relative;
    margin-left: 16px;
  }

  .table__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    position: relative;

    &:last-child {
      margin-bottom: 10px;
    }
  }

  .table__row:nth-child(even) {
    background: #F5F5F5;
  }

  .table__row:nth-child(odd) {
    background: #FFF;
  }


  .table__row > div {

    // max-width: 180px;
    // min-height: 50px;
    // background: gray;
    vertical-align: top;
  }

  .table__main-info {
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 60%;
    margin-left: 10px;

    @media screen and (max-width: 700px) {
      width: 70%;
    }

    @media screen and (max-width: 550px) {
      display: block;
      width: 50%;
    }

  }

  .table__main-info-elem {
    padding: 0 5px;
  }

  .table__foto {
    padding: 0 5px;

    a {
      display: block;
      width: 90px;
    }
  }

  .table__img {
    display: block;
    margin-bottom: 10px;
  }

  .table__info {
    width: 40%;

    @media screen and (max-width: 700px) {
      padding-top: 20px;
      width: 30%;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
    @media screen and (max-width: 550px) {
      width: 50%;
      display: block;
      text-align: center;
      margin-top: 30px;
    }
    @media screen and (max-width: 360px) {
      padding-top: 10px;
    }

  }

  .table__info-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    justify-content: space-between;
    margin-bottom: 5px;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      display: inline-block;
    }
    @media screen and (max-width: 360px) {
      font-size: 13px;
    }


  }

  .table__info-row > div {
    min-width: 65px;
    margin-right: 5px;

    @media screen and (max-width: 360px) {
      min-width: 50px
    }

  }

  .table__top-title {
    font-size: 11px;
    @media screen and (max-width: 700px) {
      height: 30px;
      line-height: 30px;

    }
  }

  .table__elem {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // padding: 0 5px;
    // width: 120px;
    margin-bottom: 10px;

    @media screen and (max-width: 700px) {
      margin-bottom: 0;
      height: 30px;
      line-height: 30px;
    }

  }

  .table__elem > div {
    display: inline-block;
    vertical-align: center;
  }

  .table__count > input {
    margin: 0;
  }

  .table__title {
    font-size: 11px;
    @media screen and (max-width: 700px) {
      // height: 45px;
    }
  }

  .table__cat {
    margin-bottom: 5px;
    font-size: 14px;
  }

  .table__name {
    margin-bottom: 20px;
    font-size: 14px;

    span {
      color: #5c369b;
    }
  }

  .table__title--cat, .table__cat {
    // display: inline-block;
  }

  .cart-list__art {
    margin-bottom: 5px;
    font-size: 14px;
  }

  .table__del {
    position: absolute;
    top: 10px;
    right: -8px;
    width: 20px;
    height: 20px;
  }

  .table__check-block {
    position: absolute;
    top: 5px;
    left: -13px;
    width: 20px;
    height: 20px;

    input[type=checkbox] {
      display: none;
    }

    label {
      display: block;
      position: relative;
      margin: 0
    }

    label:before {
      content: "";
      display: inline-block;

      width: 12px;
      height: 12px;

      position: absolute;
      left: 0px;
      top: 0;
      background-color: $white;
      border: 1px solid #ccc;


    }

    input[type=checkbox]:checked + label:before {
      content: "\2713";
      //text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
      font-size: 15px;
      color: $primary-color;
      text-align: center;
      line-height: 11px;
    }

    // input[type=checkbox] + label:hover:before {
    //   content: "\2713";
    //   text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    //   font-size: 15px;
    //   color: gray;
    //   text-align: center;
    //   line-height: 15px;
    // }
  }

}

.all__check-block {
  position: absolute;
  top: 10px;
  left: 15px;


  input[type=checkbox] {
    display: none;
  }

  label {
    display: block;
    position: relative;
    margin: 0;
    padding-left: 30px;
    color: rgba(92, 52, 153, 0.8);
    font-weight: 400;
  }

  label:before {
    content: "";
    display: inline-block;

    width: 16px;
    height: 16px;

    position: absolute;
    left: 0;
    top: 3px;
    background-color: $white;
    border: 1px solid #ccc;


  }

  input[type=checkbox]:checked + label:before {
    content: "\2713";
    //text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    font-size: 15px;
    color: $primary-color;
    text-align: center;
    line-height: 15px;
  }

  // input[type=checkbox] + label:hover:before {
  //   content: "\2713";
  //   text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
  //   font-size: 15px;
  //   color: gray;
  //   text-align: center;
  //   line-height: 15px;
  // }
}

/*  filter group   */
.category__filter.category__filter-cart {
  border: 0;
  @media screen and (max-width: 737px) {
    display: none;
  }
}

.paginator-top.paginator-cart {
  min-height: 40px;

  .pagination {
    @media screen and (max-width: 737px) {
      display: none !important;
    }
  }
}

.cart-menu {
  &__list {
    @include inline-list;
    margin-top: 15px;
    margin-left: 0;
  }

  &__item {
    margin-left: 0 !important;
    text-align: center;
    width: 14.266667%;
  }

  &__link {
    padding: 10px 0;
    border: 1px solid scale-color($primary-color, $lightness: -15%);
    // background-color: $primary-color;
    background-color: $white;
    text-transform: uppercase;
    font-size: 11px;
    color: $primary-color !important;

    //transition: background-color 300ms ease-out;
    &:hover, &:focus, &:visited, &:active {
      //background-color: scale-color($primary-color, $lightness: 7.5%);
      //color: $white !important;
      text-decoration: underline;
    }

    &:hover {
      //background-color: rgba(102, 58, 170, 0.8);
      transition: background-color 300ms ease-out;
      //color: $white !important;
      background-color: rgba(229, 229, 229, 0.6);
      color: $primary-color !important;
    }

  }

}

.cart-menu__item:nth-child(6), .cart-menu__item:nth-child(7) {
  .cart-menu__link {
    background-color: $primary-color;
    color: $white !important;
  }

  .cart-menu__link:hover {
    //background-color: rgba(102, 58, 170, 0.8);
    background-color: rgba(229, 229, 229, 0.6);
    color: $primary-color !important;
    transition: background-color 300ms ease-out;
  }
}

.cart__columns {
  @media screen and (max-width: 737px) {
    padding: 0;
  }
}

.cart__label {
  display: inline-block;
}

ul#id_need_cell {
  list-style: none;
  display: block;
  float: right;
}