// Colors

$top-menu : #f7f7f7;
$purple : #aa00ff;
$purple-light : #e1bee7;
$purple-light5: #f3e5f5;
$red-light5 : #ffebee;
$grey :#bdbdbd;
$body : #394042;
$green: #00A000;
$orange: #FF7800;
$red: #FF0000;
$orangeb: #ef8024;
$white: #fff;

// Fonts

$font-regular : 'HelveticaNeue';
$font-medium : 'HelveticaNeue-Medium';
$font-light : 'HelveticaNeue-Light';
$font-thin : 'HelveticaNeue-Thin';
$font-label: 'aa_bebas_neueregular';

//Media queries

