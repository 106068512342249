// Mobile Menu
.left-off-canvas-menu {
  background: #f5f5f5;
  width: 200px;
}

.move-right .exit-off-canvas {
  box-shadow: none;
  border-left: 1px solid #ccc;
  height: 108%;
}

.canvas-menu__header {
  text-align: center;
  margin-bottom: 10px;
}

.canvas-menu__footer {
  text-align: center;
  margin-top: 10px;
}

.off-canvas-list {
  border-top: 1px solid #ccc;
}

ul.off-canvas-list li a {
  color: inherit;
  font-family: $font-light;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  background-image: url(../img/icons/menu-arrow.png);
  background-repeat: no-repeat;
  background-position: 180px 15px;
  &:hover {
    background-color: #dadada;
  }
}
.move-right > .inner-wrap {
  transform: translate3d(200px, 0, 0);
}