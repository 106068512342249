.left.inline.comments__title {
    padding: 0;
    margin: 0;
    padding-bottom: 8px;
}
.comments__text {
    height: 118px !important;
    resize: none;
}
.row.comments__row {
    border-radius: 3px 3px 0 0;
    border-bottom: 1px solid rgba(78, 44, 130, 0.8);
}
.comments__capcha, .comments__button {
    text-align: right;
    .captcha, button {
        display: inline-block;
    }
}

.divider {
    margin: 10px;
    height: 2px;
}
.comment__list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
.comment__item {
    //border-radius: 10px;
    //border: 1px solid #cccccc;
    padding: 10px 10px;
    border-bottom: 1px solid #cccccc;
    &:last-child {
        border-bottom: none;
    }
    
}
.comment__title, .comment__date {
    margin-bottom: 5px;
    line-height: 1;
}
.comment__title {
    font-size: .95rem;
}
.comment__name {
    font-family: "Helvetica";
    //font-weight: 800;
}
.comment__date {
    font-size: .9rem;
}
.comment__text {
    p {
        font-size: .85rem;
        line-height: 1.2;
        margin-bottom: 0;
    }
}
.comment__list_answer .comment__item{
    border-bottom: none;
}
