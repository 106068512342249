.category {
  border: 1px solid #ccc;
}

.category__title {
  //border: 1px solid #ddd3ec;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  background-color: $white;
  padding: .5rem 1rem;
  color: rgba(92, 52, 153, 0.8);
  font-family: "HelveticaNeue";
  font-size: 1rem !important;
  margin: 0;
}

.category__top-desq {
  padding-left: 15px;
  padding-right: 10px;
  ul {
    list-style-position: inside;
    list-style-type: disc;
  }
}

.category__filter {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid $border-color;
  border-top: 0;
  border-left: 0;
  padding: .5rem 1rem;
  vertical-align: baseline;
  background-color: $white;

  .filters {
    top: 100%;
    right: 0;
    left: calc(100% + 1px);
    border-top: 1px solid #ddd3ec;
    position: absolute;
    background-color: white;
    z-index: 1
  }

  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + label {
    display: inline-block;
    padding: 0 1rem;
    transition: .1s;
    font-size: 12px;
    //border-radius: 25px;
    border: 1px solid transparent;
    margin: 0;
    min-width: 20px;
    text-align: center;
    color: scale-color($primary-color, $lightness: -15%);
    @media screen and (max-width: 425px) {
      font-size: 10px;
      padding: 0 .8rem;
    }
    @media screen and (max-width: 375px) {
      font-size: 10px;
      padding: 0 .5rem;
    }
    @media screen and (max-width: 345px) {
      font-size: 9px;

    }
    @media screen and (max-width: 345px) {
      padding: 0 .3rem;
    }
  }
  input[type="radio"] + label:hover {
    border: 1px solid scale-color($primary-color, $lightness: -15%);
    color: scale-color($primary-color, $lightness: -15%);
  }
  input[type="radio"]:checked + label {
    background: $primary-color;
    color: $white;
    //border: 1px solid $primary-color;
    border: 0;
  }
}

.content > .category__filter {
  border: 0;
}

.filter-group {
  &:last-child {
    width: 100%;
  }
  @include clearfix;
  margin-bottom: 4px;
  position: relative;
}

.filter-view {
  position: absolute;
  top: 0;
  right: 0;
  @media screen and (max-width: 690px) {
    display: none;
  }

  // @media screen and (max-width: 690px) {
  //   top: -33px;
  // }
  // @media screen and (max-width: 586px) {
  //   top: 0;
  // }
  // @media screen and (max-width: 525px) {
  //   top: -33px;
  // }
  // @media screen and (max-width: 462px) {
  //   display: none;
  // }

}

.filter__title {
  float: left;
  margin-right: 15px;
  font-size: 12px;
  @media screen and (max-width: 425px) {
    font-size: 11px;
  }
  @media screen and (max-width: 350px) {
    font-size: 10px;
  }

}

.filter__block {
  display: inline-block;
  font-size: 12px;
  @media screen and (max-width: 425px) {
    font-size: 11px;
  }
  @media screen and (max-width: 350px) {
    font-size: 10px;
  }

}

.filter__name {
  color: scale-color($primary-color, $lightness: -15%);
  margin-left: 10px;
  &.highlight {
    font-size: 13px;
  }
}

.category__filter input[type="radio"] + .filter__label {
  padding: 0;
  width: 20px;
  height: 20px;
}

.category__filter input[type="radio"] + .filter__label:hover {
  padding: 0;
  border-radius: 5px;
  width: 20px;
  height: 20px;
}

.category__filter input[type="radio"]:checked + .filter__label {
  //border-radius: 5px;
  border-radius: 0;
}

.filter__list {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
  &.filter__list_action {
    float: right;
    //position: absolute;
    //top: 0;
    //right: 20px;
    @media screen and (max-width: 424px) {
      display: none;
    }
  }
}

.content__title_action > .filter__list.filter__list_action {
  top: 15px;
  right: 5px;
}

.filter__item {
  display: inline-block;
  cursor: pointer;
  color: rgba(78, 44, 130, 0.25);
  // border-radius: 6px;
  // border: 1px solid rgba(78, 44, 130, 0.8);
  text-align: center;
  font-size: 14px;
  width: 27px;
  height: 27px;
  padding-top: 4px;
}

.filter__item > svg {
  fill-rule: evenodd;
  fill: rgb(124, 92, 173);
}

.filter__item:hover {
  // border: 1px solid rgba(78, 44, 130, 0.8);

  transition: background-color 0.5s ease-out;
  background-color: rgb(124, 92, 173);
  //border-radius: 3px;
}

.filter__item:hover > svg {
  //fill: rgba(78, 44, 130, 0.68);
  fill: #fff;
}

.filter__item.active {
  // border: 1px solid rgba(78, 44, 130, 0.8);
  background-color: rgb(124, 92, 173);
  //border-radius: 3px;
}

.filter__item.active > svg {
  //fill: rgba(78, 44, 130, 0.68);
  fill: #fff
}

.paginator-top {
  // border-bottom: 1px solid rgba(78, 44, 130, 0.8);
  //border: 1px solid #ddd3ec;
  border-top: 0;
  border-left: 0;
  padding: .5rem 1rem;
  vertical-align: baseline;
  // font-size: 12px;
  background: #fff;

  .pagination-wrap.center-block {
    margin: 0;
    border: 0;
  }
}

.f-product {

  &__right p {
    font-size: .8rem;
    line-height: 1rem;
    color: scale-color($primary-color, $lightness: -30%);
    @media screen and (max-width: 826px) {
      font-size: .7rem;
      margin: 0 !important;
    }
    @media screen and (max-width: 727px) {
      font-size: .6rem;
      margin: 0 !important;
    }
    @media screen and (max-width: 640px) {
      font-size: .8rem;
    }
  }

  &__right a {
    text-decoration: underline;
    color: scale-color($primary-color, $lightness: -30%);
  }

  &__right a:hover {
    text-decoration: underline;
    color: scale-color($primary-color, $lightness: -30%);
  }

  &__description {
    //width: 230px;
    margin: 0 auto;

    .cart-add {
      @media screen and (max-width: 640px) {
        text-align: center;
      }

    }

    input[type="radio"] {
      display: none;
    }
    input[type="radio"] + label {
      display: inline-block;
      border: 1px solid #e5e5e5;
      padding: 6px 6px;
      transition: .2s;
      font-size: 10px;
      vertical-align: top;

    }
  ;
    .label_l {
      border-bottom-left-radius: 25px;
      border-top-left-radius: 25px;
      margin-left: 0;
      margin-right: 0;
    }
    .label_r {
      border-bottom-right-radius: 25px;
      border-top-right-radius: 25px;
      margin-left: -6px;

    }
    input[type="radio"]:checked + label {
      background: #ef8024;
      color: #fff;
    }
    .add-to-cart {
      display: inline-block;
      margin-left: -6px;
      vertical-align: top;
      input {
        //width: 50px;
        height: 28px;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        margin: 0;
        width: 36px;
        padding: 0;
      }
      button {
        border: none;
        display: inline-block;
        vertical-align: top;
        background-color: #5c369b;
        color: #ffffff;
        padding: 5px 8px;
        height: 28px;
        font-size: 10px;
        transition: background-color 300ms ease-out 0s;
        margin-bottom: 0;
        margin-left: -5px;
        &:hover {
          background-color: #4e2e82;
        }
      }
    }
  }
  &__list {
    list-style: none;
    //border: 1px solid #ddd3ec;
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  &__item {
    @include clearfix;
    //border-bottom: 1px solid $border-color;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    //padding-left: 10px;
    //padding-right: 10px;
  }
  &__item:last-child {
    border-bottom: none;
  }
  &__img {
    text-align: center;
    max-height: 150px;
    max-width: 150px;
    overflow: hidden;
    margin: 0 auto;
    img {
      width: 100%;
      height: auto;
    }
    //margin-left: -15px;
  }
  &__art {
    display: inline-block;
    //font-size: 10px;
    font-size: .7rem;
    line-height: 13px;
    font-style: $font-regular;
  }
  &__art span {
    //font-size: 13px;
    font-size: .8rem;
  }
  &__title {
    margin-bottom: 3px;
  }
  &__title-link {
    display: inline-block;
    font-style: $font-thin;
    //font-size: 16px;
    font-size: .95rem;
    line-height: 18px;
  }
  &__price_s {
    font-size: 13px;
    font-style: $font-regular;
  }
  &__price_old {
    display: block;
    text-decoration: line-through;
    font-size: 14px;
    font-style: $font-regular;
    line-height: 14px;
    color: $monsoon;
  }

  &__price_old + &__price_sum {
    //font-size: 16px;
    font-size: 1rem;
    line-height: 1;
    font-weight: bold;
    color: red;
  }

  &__price_sum {
    font-size: .86rem;
    //font-size: 16px;
    line-height: 1;
    font-weight: bold;
  }
  &__price_l {
    //font-size: 14px;
    font-size: .8rem;
  }
  &__text {
    //font-size: 14px;

    font-size: .83rem;
    line-height: 1.2;
    margin-bottom: 18px;
    height: 48px;
  }
  &__text.open {
    height: auto;
  }
  &__read-more {
    display: none;
    font-size: .7rem;
  }
  &__stock {
    //font-size: 13px;
    font-size: .7rem;
    //margin-top: 14px;
  }
  &__stoks_ {
    color: #ef8024;
  }
  &__stoks_in {
    color: green;
  }
  &__stoks_out {
    color: red;
  }
  &__right {
    margin-top: 5px;
    margin-left: 0px;
    height: 150px;
    position: relative;
    @media screen and (max-width: 640px) {
      text-align: center;
    }
  }
  &__right > p {
    width: 90%;
    margin: 0 auto;
  }
}

.content > .f-product__list {
  border: 0;
}

.f-product__list {
  &.goods__list {
    visibility: hidden;
  }
  &.goods__list.list-greed{
    visibility: visible;
    text-align: center;
    .f-product {
    &__right {
      // height: 75px;
      height: 125px;
      margin-top: 0;
      overflow: hidden;
    }

    &__item {
      @include clearfix;
      display: inline-block;
      vertical-align: top;
      //min-height: 427px;
      width: 234px;
      //width: 180px;
      //border: 1px solid $border-color;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      overflow: hidden;

      .columns {
        //padding-left: 0.1rem;
        //padding-right: 0.1rem;
      }

    }

    &__text {
      display: none;
    }
    &__title {
      height: 37px;
      overflow: hidden;
    }
  }
  }
  &.goods__list.list-columns {
    visibility: visible;
  }
}

.theme, .sale {
  .f-product__list {
    font-family: "HelveticaNeue-Light";
    color: rgba(78, 44, 130, 0.8);
    &.list-greed {
      //text-align: justify;
      .f-product__item {
        margin: 0 25px;
      }
      .columns {
        //text-align: center;
      }
    }

    .f-product__title {
      height: auto;
      font-size: 20px;
    }
  }

}
